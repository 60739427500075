import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { connect, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { logout } from '../../containers/session/sessionSlice';
import { Link } from 'react-router-dom';
import { FactCheck } from '@mui/icons-material';


export const VerificationSteps : React.FC<{session: any}> = ({session}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();



  return (
    <React.Fragment>
      <h2 className="h5 mt-4 font-weight-bold">Verification Steps</h2>

      <ul className="list-unstyled">
      
      <li className="d-flex align-items-center py-3">
        {session.session.currentUser?.email_verified != true && (
          <>
          E-mail address Verification
          <div className="ml-auto">
            <Link to="/verify/email" className="btn btn-sm btn-outline-primary rounded-lg px-3 py-0 ">
                Verify
            </Link>
          </div>
          </>)}
          {session.session.currentUser?.email_verified == true && (
            <>
              E-mail address : <br/>{session.session.currentUser?.email}
              <small className="ml-auto text-success">
                <FactCheck className="mr-2" /> Verified
              </small>
            </>)}
        </li>
        
        
        <li className="d-flex align-items-center py-3">
        {session.session.currentUser?.mobile_verified != true && (
          <>
          Mobile Verification
          <div className="ml-auto">
            <Link to="/verify/mobile" className="btn btn-sm btn-outline-primary rounded-lg px-3 py-0 ">Verify</Link>
          </div>
          </>)}
          {session.session.currentUser?.mobile_verified == true && (
            <>
              Mobile phone : <br/>{session.session.currentUser?.mobile}
              <small className="ml-auto text-success">
                <FactCheck className="mr-2" /> Verified
              </small>
            </>)}
        </li>
        <li className="d-flex align-items-center py-3">
          {session.session.currentUser?.banking_verified != true && (
          <>
          Banking Verification
          <div className="ml-auto">
            <Link to="/verify/banking" className="btn btn-sm btn-outline-primary rounded-lg px-3 py-0 ">Verify</Link>
          </div>
          </>)}
          {session.session.currentUser?.banking_verified == true && (
            <>
              Bank : <br/>{session.session.currentUser?.banking_verification_data[0]?.instutition}
              <small className="ml-auto text-success">
                <FactCheck className="mr-2" /> Verified
              </small>
            </>)}
        </li>
        

        <li className="d-flex align-items-center py-3">
          Government ID
          <div className="ml-auto">
            <Link to="/verify/governmentid" className="btn btn-sm btn-outline-primary rounded-lg px-3 py-0 ">Verify</Link>
          </div>
        </li>
        {/* <li className="d-flex align-items-center py-3">
          Mobile phone{" "}
          <small className="ml-auto text-success">
            <FactCheck className="mr-2" /> Verified
          </small>
        </li>
        <li className="d-flex align-items-center py-3">
          Government ID{" "}
          <small className="ml-auto text-success">
            <FactCheck className="mr-2" /> Verified
          </small>
        </li> */}
        
        <li className="d-flex align-items-center py-3">
          Employment Verification
          <div className="ml-auto">
            <Link to="/verify/employment" className="btn btn-sm btn-outline-primary rounded-lg px-3 py-0 ">Verify</Link>
          </div>
        </li>
        <li className="d-flex align-items-center py-3">
          Selfie
          <div className="ml-auto">
            <Link to="/verify/photo" className="btn btn-sm btn-outline-primary rounded-lg px-3 py-0 ">Verify</Link>
          </div>
        </li>
      </ul>
    </React.Fragment>
  );
}

const mapStateToProps = (state : any) => ({ session: state.session })

export default connect(mapStateToProps)(VerificationSteps);