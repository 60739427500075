import { LinearProgress, CardMedia, AppBar, Drawer, ListItem } from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import sessionSlice, { getCurrentUserAsync, logout } from "../../containers/session/sessionSlice";
import Nav from "../nav/nav";

import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRightOutlined';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { AddOutlined, Menu as MenuIcon } from "@mui/icons-material";
import TopBar from "../topbar/topbar";
import { getAccessToken } from "../../services/utils";
import { NavLink } from "react-router-dom";
import { RouterLink } from "..";
import { AccountMenu } from "../accountmenu/accountmenu";
import { ChatBox } from "../chatbox/chatbox";



const SplashLoading = () => (
  <Box style={{ height: '100vh', top: 0, left: 0, position: 'relative' }}>
    <CardMedia
      component="img"
      image="/logo-moneymoves.svg"
      alt="Money Moves Logo"
      style={{width: '200px', paddingTop: '30%', margin: '0 auto' }}
    />
    <LinearProgress style={{ width: '100%', marginTop: '15px' }} />
  </Box>
);


const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  session: any;
  isBusy: boolean;
  nav: boolean;
  dark: boolean;
  checkLogin: boolean;
  children: JSX.Element;
  back: boolean;
  logoCenter: boolean;
  headerLogo: boolean;
  topbar: boolean;
}

export const Layout: React.FC<{ session: any, topbar: boolean, back: boolean, logoCenter: boolean; headerLogo: boolean, isBusy: boolean, nav: boolean, dark: boolean, checkLogin: boolean, children: JSX.Element }> = (props: Props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isBusy, setIsBusy] = useState(true);



  useEffect(() => {

    const checkUserAuthentication = async () => {
      const accessToken = await getAccessToken();

      if (accessToken) {
        const currentUser : any = await dispatch(getCurrentUserAsync());

        if (currentUser.payload.data != null) {
          if(currentUser.payload.data.mobile_verified != true && location.pathname.startsWith('/verify/mobile') == false)
          {
            navigate('/verify/mobile');
          }
        }
        else {
          navigate('/sign-in');
        }

        // try {
        //   await dispatch(getCurrentUserAsync());
        //   if (props.session.session.currentUser != null) {

        //     if(props.session.session.currentUser.mobile_verified != true && location.pathname.startsWith('/verify/mobile') == false)
        //     {
        //       navigate('/verify/mobile');
        //     }
        //     else
        //     {
        //       navigate('/sign-in');
        //     }

        //     setIsBusy(false);
        //   }
        //   else {
        //     navigate('/sign-in');
        //   }
        // } catch (error) {
        //   navigate('/sign-in');
        // }
      }
      else {
        console.log('5:else');
        navigate('/sign-in');
      }

    };

    if (props.checkLogin == true) checkUserAuthentication();
  }, []);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box sx={{ display: props.nav == false ? 'none' : { xs: 'none', sm: 'block' } }}>
      <Toolbar style={{ backgroundColor: 'rgb(16, 70, 154)' }}>
        <CardMedia
          component="img"
          image="/logo-white.svg"
          alt="Money Moves Logo"
          style={{ width: '80%' }}
        />
      </Toolbar>
      <Divider />
      <List>

        <RouterLink to="/" text="Home" icon={<img src={location.pathname === "/" ? "/homeActive.svg" : "/home.svg"} />} />

        <RouterLink to="/new-loan" text="New Loan" icon={<AddOutlined style={{ color: location.pathname == "/new-loan" ? '#2A85D6' : '' }}></AddOutlined>} />

        <RouterLink to="/applications" text="Applications" icon={<img src={location.pathname === "/applications" ? "/applicationsActive.svg" : "/applications.svg"} />} />

        <RouterLink to="/employments" text="Employments" icon={<img src={location.pathname === "/employments" ? "/activityActive.svg" : "/activity.svg"} />} />

        <RouterLink to="/address" text="Addresses" icon={<img src={location.pathname === "/addresses" ? "/activityActive.svg" : "/activity.svg"} />} />


      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return props.isBusy == true? <SplashLoading /> :
    (
      <>
      <div className="App bg-gradient px-0" style={{ height: "100vh", position: "relative" }} id="app">
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />

          <Box
            hidden={!props.nav}
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}

          >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 0, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
          >
            {props.topbar && (<Box sx={{ display: { sm: 'none' } }}><TopBar back={props.back} center={props.logoCenter} dark={props.dark} logo={props.headerLogo} /></Box>)}
            {props.topbar && (
              <Toolbar sx={{ display: { xs: 'none', sm: 'flex', justifyContent: 'end' } }} style={{ backgroundImage: 'linear-gradient(-90deg, #2A85D6, #10469A)' }}>
                <AccountMenu session={props.session}></AccountMenu>
              </Toolbar>)}
            {props.children}
            {props.nav ? <Box sx={{ display: { xs: 'flex', sm: 'none' }, margin: 0, padding: 0, paddingLeft: 0 }}><Nav /></Box> : undefined}
            {props.session.session.currentUser &&(<Box>Test</Box>)}  
          </Box>
          
          
        </Box>
        
      </div>
      {/* {props.session.session.currentUser &&(<ChatBox session={props.session}></ChatBox>)} */}
      </>
    )


}

const mapStateToProps = (state: any) => ({ session: state.session})

export default connect(mapStateToProps)(Layout);