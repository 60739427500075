import { IoIosArrowBack } from 'react-icons/io'
import { useNavigate } from "react-router-dom";

export default function BackButton({ dark = false }) {
  const navigate = useNavigate();

  return (
    <button onClick={() => navigate(-1)} className={`btn btn-link pl-0 ${dark ? 'text-white' : 'text-dark'}`}><IoIosArrowBack /></button>
  )
}
