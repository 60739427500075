import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {} from '../../../applications/applicationSlice';

interface Props
{ 
  session: any,
  status : string, 
  date : string, 
  applicationType : string,
  amount : string
}

export const ApplicationCard : React.FC<Props> = (props: Props) => {
   const dispatch = useDispatch();

   const statusColor = () => {
    if (props.status === "Pending") return "#f19824"
    else if (props.status === "Rejected") return "#f55053"
    else return "#00b167"
}

return (
    <div className="d-flex align-items-center py-3">
        {/* <img src={src} /> */}
        {props.applicationType === "Power Sports" ? <img src="/icon-power-sports.svg" /> : undefined}
        {props.applicationType === "Cash Loan" ? <img src="/icon-cash-loan.svg" /> : undefined}
        <div className="d-flex flex-column align-items-start ml-3">
            <p className="m-0"><strong>${props.amount}</strong></p>
            <small>{props.applicationType}</small>
        </div>
        <div className="d-flex flex-column align-items-end ml-auto">
            <p className="m-0" style={{ color: `${statusColor()}` }}>{props.status}</p>
            <small>{props.date}</small>
        </div>
    </div >
)}
  
    
const mapStateToProps = (state: any) => ({ session: state.session })

export default connect(mapStateToProps)(ApplicationCard);