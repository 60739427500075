import React, { FormEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
//import styles from './Login.module.css';
import { loginAsync, sessionSlice } from './sessionSlice';

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../components/layout/layout';
import { Title } from '@mui/icons-material';
import { Box } from '@mui/material';

const Activities = (session: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [isBusy, setIsBusy] = useState(true);

    useEffect(() => {
       
    }, [session]);

    return (
        <Layout headerLogo={true} logoCenter={true} back={true} topbar={true}  session={session} dark={true} nav={true} checkLogin={false} isBusy={session.session.isBusy}>
            <Box p={3}>Activities</Box>
        </Layout>
    );
}

const mapStateToProps = (state: any) => ({ session: state.session })

export default connect(mapStateToProps)(Activities);