import React, { ChangeEventHandler, FormEvent, Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
//import styles from './Login.module.css';
import { loginAsync, sessionSlice } from '../session/sessionSlice';

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../components/layout/layout';
import { Title } from '@mui/icons-material';
import { Box } from '@mui/material';
import { FiHelpCircle } from 'react-icons/fi'
import { AiOutlineLock } from 'react-icons/ai'
import { IoIosArrowBack } from 'react-icons/io'


import { NavLink } from 'react-router-dom';
import VerificationInput from 'react-verification-input';
import BackButton from '../../components/backbutton/back-button';

import { FaCheck } from "react-icons/fa";
import Webcam from "react-webcam";


const VerificationPhoto = (session: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [isBusy, setIsBusy] = useState(true);

    const [checkQuality, setCheckQuality] = useState(false);
    const webcamRef = useRef<Webcam>(null);
    const [imgSrc, setImgSrc] = useState<string | null>(null);

    const capture = () => {
        const getImg = webcamRef?.current?.getScreenshot();
        console.log(getImg);
        setImgSrc(getImg != null ? getImg : '');
    };

    

    const handleConfirm = () => {
        setCheckQuality(true);
        capture();
    };

    return (
        <Layout headerLogo={true} logoCenter={true} back={true} topbar={false} session={session} dark={true} nav={false} checkLogin={false} isBusy={session.session.isBusy}>
            <>

                <div className="px-3">
                    <BackButton />
                </div>
     
                <div className='mb-3'></div>

                <Box sx={{ p: 0, maxWidth: { sm: `26.56rem` }, marginLeft: 'auto', marginRight: 'auto' }}>

                {!checkQuality ? (
                <div className="d-flex flex-column h-100 bg-dark">
                    <Webcam audio={false} ref={webcamRef} screenshotFormat="image/png" />
                    <div className="px-3 text-center py-5">
                        <p className="text-white h4 font-weight-bold">Center your face</p>
                        <p className="text-white">Align your face to the center of the selfie area and then take a photo</p>
                        <button className="btn btn-primary rounded-lg px-3" onClick={handleConfirm}>
                            <FaCheck />
                        </button>
                    </div>
                </div>
            ) : (
                <div>
                    

                    <div className="shadow">
                        <div className="card shadow-lg rounded-lg border-0 mb-4 text-center px-3 pb-bottom-app">
                            <img src={imgSrc != null ? imgSrc : ''} alt="" className="img-fluid w-50 shadow-lg rounded-lg mx-auto" style={{ marginTop: "-5rem" }} />
                            <div className="mt-5">
                                <img src="/icon-check-quality.svg" alt="" />
                                <h4 className="font-weight-bold mt-4">Check quality</h4>
                                <p>Please make sure your card details are clear to read with no blur or glare.</p>
                                <div className="mt-5">
                                    <Link to="/verify">
                                        <a className="btn btn-primary btn-block shadow rounded-lg">All clear. Continue</a>
                                    </Link>
                                    <button className="btn btn-light bg-transparent" onClick={() => setCheckQuality(false)}>
                                        Take a new photo
                                    </button>
                                </div>
                            </div>
           
                        </div>
                    </div>
                </div>
            )}

                </Box>  

            </>
        </Layout>
    );
}

const mapStateToProps = (state: any) => ({ session: state.session })

export default connect(mapStateToProps)(VerificationPhoto);