import React, { ChangeEventHandler, FormEvent, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
//import styles from './Login.module.css';
import { loginAsync, sessionSlice } from '../session/sessionSlice';

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../components/layout/layout';
import { Title } from '@mui/icons-material';
import { Box } from '@mui/material';
import { FiHelpCircle } from 'react-icons/fi'
import { AiOutlineLock } from 'react-icons/ai'
import { IoIosArrowBack } from 'react-icons/io'


import { NavLink } from 'react-router-dom';
import VerificationInput from 'react-verification-input';
import BackButton from '../../components/backbutton/back-button';
import helmet from 'helmet';



const VerificationBanking = (session: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [isBusy, setIsBusy] = useState(true);

    const [confirm, setConfirm] = useState(false);
    const [phone, setPhone] = useState("");

    const getPhone = (event : any) => {
        const phoneNumber = event.target.value;
        const formated = `(${phoneNumber.substr(0, 3)}) ${phoneNumber.substr(3, 3)}-${phoneNumber.substr(6, 4)}`;
        setPhone(formated);
    };

    return (
        
        <Layout headerLogo={true} logoCenter={true} back={true} topbar={false} session={session} dark={true} nav={false} checkLogin={false} isBusy={session.session.isBusy}>
            <>

            <div className="px-3">
                    <button onClick={() => navigate('/verify')} className={`btn btn-link pl-0 text-dark`}><IoIosArrowBack /></button>
                </div>
     
        <div className='mb-3'></div>

        <Box sx={{ p: 0, maxWidth: { sm: `26.56rem` }, marginLeft: 'auto', marginRight: 'auto' }}>
     
        <iframe className='flinks-iframe'
            src={`https://toolbox-iframe.private.fin.ag/?demo=true&redirectUrl=${process.env.REACT_APP_FLINK_RESULT_URL}&innerRedirect=true&theme=light&consentEnable=true&customerName=FinTech&backgroundColor=f7f7f7&foregroundColor1=000000&desktopLayout=true&headerEnable=false&institutionFilterEnable=true`}>
        </iframe>

        </Box>  

            </>
        </Layout>
    );
}

const mapStateToProps = (state: any) => ({ session: state.session })

export default connect(mapStateToProps)(VerificationBanking);