import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
//import styles from './Login.module.css';

import styles from './Dashboard.module.css';

import { Layout } from '../../components/layout/layout';
import { Link, useNavigate } from 'react-router-dom';
import { loginAsync, sessionSlice } from '../session/sessionSlice';
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ChevronRight, FactCheck } from '@mui/icons-material';
import Verificationsteps, { VerificationSteps } from '../../components/verificationsteps/verificationsteps';
import { Programs } from '../../components/programs/programs';
import Loanbalance, { LoanBalance } from '../../components/loanbalance/loanbalance';


const Dashboard = (session: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isBusy, setIsBusy] = useState(session.session.isBusy);
  const [details, setDetails] = useState(false);
  

  
   

  

  return (
    <Layout headerLogo={true} logoCenter={true} back={false} topbar={true} session={session} dark={true} nav={true} checkLogin={true} isBusy={session.session.isBusy}>
      <>
      <div className="bg-gradient-dark text-white pb-5">
        
        <div className="px-3 pb-4">

        <LoanBalance session={session}></LoanBalance>

        </div>
      </div>

      <Box sx={{ p: 0, maxWidth: { sm: `26.56rem` }, marginLeft: 'auto', marginRight: 'auto' }}>
      <div className="bg-white px-3 shadow rounded-top-lg mt-neg pb-bottom-app">
        <div className="card shadow-sm mt-neg-lg rounded-lg border-none">
          
          <div className="card-body d-flex align-items-center py-2">
            <div className="w-100">
              <p className="m-0">
                <small className="text-muted">Available Credit</small>
              </p>
              <p className="h4 m-0">${session.session.currentUser?.credit_detail?.available_credit}</p>
            </div>
            <div className="col-auto px-0">
              <Link to="/transfer" style={{ color: "#0bb8e4", fontSize: "1.2rem" }}>
                  <small>Transfer funds</small>
                  <img src="/arrow.svg" />
              </Link>
            </div>
          </div>
          {details ?
            <div className="card-body d-flex border-top py-1 align-items-center">
              <div className="w-100">
                <p className="text-muted m-0">
                  <small>Next payment</small>
                </p>
                <p className="m-0">{session.session.currentUser.credit_detail?.next_payment.date}</p>
              </div>
              <span className="h4 m-0 font-weight-bold">${session.session.currentUser.credit_detail?.next_payment.price}</span>
            </div> : undefined
          }
          <div className="card-footer bg-transparent py-1 text-muted text-center" onClick={() => setDetails(!details)}>
            {!details ? "View" : "Hide"} Details
          </div>
        </div>

        
        <VerificationSteps session={session}></VerificationSteps>
        
        <Programs session={session}></Programs>
        

      </div >
      </Box>
      </>
      
    </Layout>
  );
}

const mapStateToProps = (state: any) => ({ session: state.session })

export default connect(mapStateToProps)(Dashboard);