import { NavLink } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Nav() {
    const navigate = useNavigate();
    const location = useLocation();
    

    return (
        <div
            style={{
                position: "fixed",
                bottom: 0
            }}
            className="col-sm-12 pb-2 bg-white px-3 d-flex align-items-end justify-content-between shadow-lg"
        >
            <NavLink to="/" className="active">
                <div className="d-flex flex-column justify-content-center  align-items-center ">
                    <img src={location.pathname === "/" ? "/homeActive.svg" : "/home.svg"} />
                        <small>Home</small>
                </div>
            </NavLink>

            <NavLink to="/applications">
                <div className="d-flex flex-column justify-content-center  align-items-center ">
                    <img src={location.pathname === "/applications" ? "/applicationsActive.svg" : "/applications.svg"} />
                    <small>Applications</small>
                </div>
            </NavLink>

            <NavLink to="/new-loan">
                <div className="d-flex flex-column  align-items-center">
                    <div className="rounded-circle mt-neg-lg d-flex flex-column justify-content-end align-items-center">
                        <span className="bg-white rounded-circle p-2">
                            <img src="/icon-btn-new.svg" className="rounded-circle" style={{ boxShadow: "0 .3rem .7rem rgba(23,97,197,.7)" }} />
                        </span>
                    </div>
                    <small>New Loan</small>
                </div>
            </NavLink>

            <NavLink to="/activities">
                <div className="d-flex flex-column justify-content-center  align-items-center ">
                    <img src={location.pathname === "/activities" ? "/activityActive.svg" : "/activity.svg"} />
                    <small>Activities</small>
                </div>
            </NavLink>

            <NavLink to="/profile">
                <div className="d-flex flex-column justify-content-center  align-items-center ">
                    <img src={location.pathname === "/profile" ? "/profileActive.svg" : "/profile.svg"} />
                    <small>Profile</small>
                </div>
            </NavLink>
        </div>
    );
}
