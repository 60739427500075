import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { connect, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { logout } from '../../containers/session/sessionSlice';
import { Link } from 'react-router-dom';
import { ChevronRight, FactCheck } from '@mui/icons-material';


export const Programs : React.FC<{session: any}> = ({session}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [programs, setPrograms] = React.useState([]);
  
  React.useEffect(() => {
    setPrograms(session?.session?.currentUser?.options_program);
  }, []);

  return (
    <React.Fragment>
        <h2 className="h5 mt-4 font-weight-bold">Loan programs</h2>

        

         {programs && programs.map((item :any, i) => (
           <div key={i} className="bg-light p-2 rounded-lg d-flex align-items-center mb-3">
           <img src="/icon-home-improvement.svg" alt="" className="mr-3" style={{ width: "2.5rem" }} />
            {item.label}
           <span className="ml-auto mr-3"><ChevronRight /></span>
           </div>
          )
         )}

        

        
    </React.Fragment>
  );
}

const mapStateToProps = (state : any) => ({ session: state.session })

export default connect(mapStateToProps)(Programs);