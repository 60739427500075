import axios from 'axios';
import { generateApiEndpoint, localStorageKeys } from './utils';

let isRefreshing: boolean = true;
let failedQueue: any[] = [];

const processQueue = (error : any, accessToken = null) => {
  failedQueue.forEach((promise: any) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(accessToken);
    }
  });

  failedQueue = [];
};

// Add header interceptor.
axios.interceptors.request.use(
  async (config : any) => {
    const accessToken = localStorage.getItem(
      localStorageKeys.accessToken
    );

    // Set access token header.
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error : any) => {
    return Promise.reject(error);
  }
);

// Request retrying mechanism if there's a refresh token stored.
axios.interceptors.response.use(
  async (response : any) => {
    return response;
  },
  async (error : any) => {

    return Promise.reject(error);
  }
);

export default axios;
