import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {sessionSlice} from '../../../session/sessionSlice';

interface Props {
  session: any;
}

export const ProfileCard : React.FC<{session: any}> = ({session}) => {
   const dispatch = useDispatch();

    return (
      <div>
        <div className="bg-white p-1 d-inline-block rounded-circle mb-3">
          <img style={{borderRadius: '200px'}}
            src="/unknown_person.jpg"
            alt="..."
          />
        </div>
        <h5 className="font-weight-bold mb-1">{session?.currentUser?.name}</h5>
        <small>{session?.currentUser?.email}</small>
      </div>
    );
  }
  
    
const mapStateToProps = (state: any) => ({ session: state.session })

export default connect(mapStateToProps)(ProfileCard);