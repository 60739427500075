import React, { FormEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
//import styles from './Login.module.css';
import { loginAsync, sessionSlice } from '../session/sessionSlice';

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../components/layout/layout';
import { FactCheck, Title } from '@mui/icons-material';
import { Box } from '@mui/material';
import { FiHelpCircle } from 'react-icons/fi'
import { AiOutlineLock } from 'react-icons/ai'
import { IoIosArrowBack } from 'react-icons/io'


import { NavLink } from 'react-router-dom';
import BackButton from '../../components/backbutton/back-button';


const VerificationList = (session: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [isBusy, setIsBusy] = useState(true);

    setTimeout(function(){setIsBusy(false)}, 1000);

    useEffect(() => {
       
    }, [session]);

    return (
        <Layout headerLogo={true} logoCenter={true} back={true} topbar={false} session={session} dark={true} nav={false} checkLogin={false} isBusy={session.session.isBusy}>
            <>
     
            <div className="px-3">
            <button onClick={() => navigate('/')} className={`btn btn-link pl-0 text-dark`}><IoIosArrowBack /></button>
                </div>
        <div className='mb-3'></div>

        
      <div className="px-3 pb-bottom-app">

      <Box sx={{ p: 0, maxWidth: { sm: `26.56rem` }, marginLeft: 'auto', marginRight: 'auto' }}>
      <Link to="/verify/email" className="card border-0 pt-3 pb-2 px-3 shadow-lg rounded-lg text-dark mb-4">
            <span className="d-flex align-items-start">
              <img src="/icon-mobile-phone.svg" alt="" className="col-3 pl-0" />
              <span className="d-flex flex-column">
                <span className="h6 mb-1">Email address</span>
                <small>We will send you a code to your email address that you must confirm.</small>
                {session.session.currentUser.email_verified != true && (<span className="d-flex align-items-center text-primary mt-2"><AiOutlineLock /><span className="ml-2">Get the code</span></span>)}
                {session.session.currentUser.email_verified == true && (<small className="d-flex align-items-center text-primary mt-2 text-success"><FactCheck className="mr-2" /> Verified</small>)} 
                
                </span>
            </span>
        </Link>

        <Link to="/verify/mobile" className="card border-0 pt-3 pb-2 px-3 shadow-lg rounded-lg text-dark mb-4">
            <span className="d-flex align-items-start">
              <img src="/icon-mobile-phone.svg" alt="" className="col-3 pl-0" />
              <span className="d-flex flex-column">
                <span className="h6 mb-1">Mobile Phone</span>
                <small>We will send you a code to your phone that you must confirm.</small>
                {session.session.currentUser.mobile_verified != true && (<span className="d-flex align-items-center text-primary mt-2"><AiOutlineLock /><span className="ml-2">Get the code</span></span>)}
                {session.session.currentUser.mobile_verified == true && (<small className="d-flex align-items-center text-primary mt-2 text-success"><FactCheck className="mr-2" /> Verified</small>)}
              </span>
            </span>
        </Link>

        <Link to="/verify/government-id">
          <a className="card border-0 pt-3 pb-2 px-3 shadow-lg rounded-lg text-dark mb-4">
            <span className="d-flex align-items-start">
              <img src="/icon-government-id.svg" alt="" className="col-3 pl-0" />
              <span className="d-flex flex-column">
                <span className="h6 mb-1">Government ID</span>
                <small>We will send you a code to your phone that you must confirm.</small>
                <span className="d-flex align-items-center text-primary mt-2"><AiOutlineLock /><span className="ml-2">Take a photo</span></span>
              </span>
            </span>
          </a>
        </Link>

        <Link to="/verify/banking" className="card border-0 pt-3 pb-2 px-3 shadow-lg rounded-lg text-dark mb-4">
            <span className="d-flex align-items-start">
              <img src="/icon-bank-verification.svg" alt="" className="col-3 pl-0" />
              <span className="d-flex flex-column">
                <span className="h6 mb-1">Banking Information</span>
                <small>We will send you a code to your phone that you must confirm.</small>
                {session.session.currentUser.banking_verified != true && (<span className="d-flex align-items-center text-primary mt-2"><AiOutlineLock /><span className="ml-2">Fill a form</span></span>)}
                {session.session.currentUser.banking_verified == true && (<small className="d-flex align-items-center text-primary mt-2 text-success"><FactCheck className="mr-2" /> Verified</small>)}
                
              </span>
            </span>
        </Link>

        <Link to="/verify/employment" className="card border-0 pt-3 pb-2 px-3 shadow-lg rounded-lg text-dark mb-4">
            <span className="d-flex align-items-start">
              <img src="/icon-employment-verification.svg" alt="" className="col-3 pl-0" />
              <span className="d-flex flex-column">
                <span className="h6 mb-1">Employment Verification</span>
                <small>We will send you a code to your phone that you must confirm.</small>
                <span className="d-flex align-items-center text-primary mt-2"><AiOutlineLock /><span className="ml-2">Fill a form</span></span>
              </span>
            </span>
        </Link>


        <Link to="/verify/photo" className="card border-0 pt-3 pb-2 px-3 shadow-lg rounded-lg text-dark mb-4">
            <span className="d-flex align-items-start">
              <img src="/icon-selfie.svg" alt="" className="col-3 pl-0" />
              <span className="d-flex flex-column">
                <span className="h6 mb-1">Selfie photo</span>
                <small>We will send you a code to your phone that you must confirm.</small>
                <span className="d-flex align-items-center text-primary mt-2"><AiOutlineLock /><span className="ml-2">Take a selfie</span></span>
              </span>
            </span>
        </Link>
      </Box>

      </div>


            </>
        </Layout>
    );
}

const mapStateToProps = (state: any) => ({ session: state.session })

export default connect(mapStateToProps)(VerificationList);