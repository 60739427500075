import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';


const ProtectedRoute: React.FC<{ session: any, children : JSX.Element}> = ({ session, children }) => {
  
  console.log(session);
  // if (!session.isL) {
  //   return <Navigate to="/sign-in" replace />;
  // }
  return children;
};

const mapStateToProps = (state : any) => ({ session: state.session })

export default connect(mapStateToProps)(ProtectedRoute);