import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';

import { Layout } from '../../components/layout/layout';
import { useNavigate } from 'react-router-dom';
import { loginAsync, sessionSlice } from '../session/sessionSlice';
import { Box } from '@mui/material';



const Transfer = (session: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isBusy, setIsBusy] = useState(true);

  setTimeout(function(){setIsBusy(false)}, 1000);

  return (
    <Layout headerLogo={true} logoCenter={true} back={true} topbar={true}  session={session} dark={false} nav={true} checkLogin={true} isBusy={session.session.isBusy}>
      <Box p={3}>Transfer</Box>
    </Layout>
  );
}

const mapStateToProps = (state: any) => ({ session: state.session })

export default connect(mapStateToProps)(Transfer);