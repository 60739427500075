import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { connect, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { logout } from '../../containers/session/sessionSlice';
import { Link } from 'react-router-dom';
import { ChevronRight, FactCheck } from '@mui/icons-material';


export const LoanBalance : React.FC<{session: any}> = ({session}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      
        <div className="d-flex flex-column align-items-center">
        {session.session.currentUser?.loan_balance != null && (
          <>
          <p className="mb-2 h6">Loan Balance</p>
          <p className="h1">${session.session.currentUser?.loan_balance}</p>
          </>
        )}
        <Link to="/new-loan">
            <span className="btn btn-outline-light btn-sm mt-2 px-3 py-2 border-light rounded-lg ft-color-white">Get a new loan now</span>
        </Link>
    </div>
      
        
    </React.Fragment>
  );
}

const mapStateToProps = (state : any) => ({ session: state.session })

export default connect(mapStateToProps)(LoanBalance);