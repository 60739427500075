import React from 'react';
import "./styles/App.scss";

import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';

import { Login , Dashboard, ForgotPassword, Applications, NewApplication, Profile, Activities, Transfer, Employments, Addresses} from './app/containers';
import Register from './app/containers/session/Register';
import Verificationlist from './app/containers/verification/verificationlist';
import VerificationMobile from './app/containers/verification/verificationmobile';
import VerificationGovernmentID from './app/containers/verification/verificationgovernmentid';
import VerificationBanking from './app/containers/verification/verificationbanking';
import VerificationBankingResult from './app/containers/verification/verificationbanking-result';
import VerificationPhoto from './app/containers/verification/verificationphoto';
import VerificationEmail from './app/containers/verification/verificationemail';


function App() {
  return (
    <BrowserRouter>      
        <Routes>
          <Route path='/' element={<Dashboard></Dashboard>} />
          <Route path="/sign-in" element={<Login></Login>} />
          <Route path="/sign-up" element={<Register></Register>} />
          <Route path="/recover-password" element={<ForgotPassword></ForgotPassword>} />
          <Route path="/applications" element={<Applications></Applications>} />          
          <Route path="/employments" element={<Employments></Employments>} />     
          <Route path="/address" element={<Addresses></Addresses>} />     
          <Route path="/new-loan" element={<NewApplication></NewApplication>} />  
          <Route path="/profile" element={<Profile></Profile>} />          
          <Route path="/activities" element={<Activities></Activities>} /> 
          <Route path="/transfer" element={<Transfer></Transfer>} />     
          <Route path="/verify" element={<Verificationlist></Verificationlist>} />     
          <Route path="/verify/mobile" element={<VerificationMobile></VerificationMobile>} /> 
          <Route path="/verify/email" element={<VerificationEmail></VerificationEmail>} /> 
          <Route path="/verify/government-id" element={<VerificationGovernmentID></VerificationGovernmentID>} />
          <Route path="/verify/banking" element={<VerificationBanking></VerificationBanking>} />
          <Route path="/verify/banking-result" element={<VerificationBankingResult></VerificationBankingResult>} />
          <Route path="/verify/photo" element={<VerificationPhoto></VerificationPhoto>} />    
           
        </Routes>
    </BrowserRouter>
  )
}

export default App;
