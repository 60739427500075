import { IoIosArrowBack } from 'react-icons/io'
import { useNavigate } from "react-router-dom";
import Accountmenu from '../accountmenu/accountmenu';

export default function TopBar({ logo = true, back = true, dark = false, center = false }) {
    const navigate = useNavigate();
    const margin = "mr-auto";

    return (
        <div className="bg-gradient-dark text-white pb-1">
            <div className="py-3 px-3 d-flex justify-content-center">
                
                    <span className="mr-auto">
                        {!!!back ? (<span className="btn btn-link pl-0 pr-0 text-white" style={{width: '18px'}}>&nbsp;</span>) : (
                        <button onClick={() => navigate(-1)} className={`btn btn-link pl-0 pr-0 ${dark ? 'text-white' : 'text-dark'}`}><IoIosArrowBack /></button>
                        )}
                    </span>
                
                {logo ? <img style={{display: 'block'}} className={margin} src="/logo-white.svg" /> : undefined}
                {/* {icon && <img src={`/${icon}.svg`} />} */}
                
            </div>
        </div>
    );
}


