import React, { ChangeEventHandler, FormEvent, Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
//import styles from './Login.module.css';
import { getCurrentUserAsync, loginAsync, sendEmailCodeAsync, sendMobileCodeAsync, sessionSlice, verifyEmailCodeAsync, verifyMobileCodeAsync } from '../session/sessionSlice';

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../components/layout/layout';
import { Title } from '@mui/icons-material';
import { Box,FormControl} from '@mui/material';
import { FiHelpCircle } from 'react-icons/fi'
import { AiOutlineLock } from 'react-icons/ai'
import { IoIosArrowBack } from 'react-icons/io'


import { NavLink } from 'react-router-dom';
import VerificationInput from 'react-verification-input';
import BackButton from '../../components/backbutton/back-button';
import MuiPhoneNumber from 'material-ui-phone-number';
import { SubmitHandler, useForm } from 'react-hook-form';

type VerificationValues = {
    code: string;
};

const VerificationEmail = (session: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [code,setCode] = useState('');

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
        setValue,
        setError,
        clearErrors,
        trigger,
        getValues
    } = useForm<VerificationValues>({
        mode: 'all',
        reValidateMode: 'onChange',
    });

    const onSubmit: SubmitHandler<VerificationValues> = async (data) => {
        
        if(confirm == true && code != '')
        {
            verifyConfirmCode();
        }
        else
        {
            return false;
        }
        
        
    }

    

    const [confirm, setConfirm] = useState(true);
    const [isSentCode, setIsSentCode] = useState(false);
    const [time,setTime] = useState(0);
    
    const intervalRef = useRef<any>();

    

    useEffect(() => {
        
        if(time > 0)
        {
            intervalRef.current = setInterval(() => {
                console.log(time);
                setTime((t) => {
                    return t - 1
                });
            
            }, 1000);
        }
        return () => clearInterval(intervalRef.current);
      }, [time]);



    const stopTimer = () =>
    {
        clearTimeout(intervalRef.current);
        setConfirm(false);
        setTime(0);
    }

    const sendConfirmCode = async() => {
        const result = await dispatch(sendEmailCodeAsync({}));
        
        
        setConfirm(true);
        setTime(180);
        setIsSentCode(true);


    }
    

    useEffect(() => {
        if(isSentCode == false)
        {
            sendConfirmCode();
        }
      }, [isSentCode]);
      
    
    const verifyConfirmCode = async() => {
        const result = await dispatch(verifyEmailCodeAsync({ code : code}));
        await dispatch(getCurrentUserAsync());

        if(result.payload.data == true)
        {
            navigate('/');
        }

    }


    

    return (
        <Layout headerLogo={true} logoCenter={true} back={true} topbar={false} session={session} dark={true} nav={false} checkLogin={false} isBusy={session.session.isBusy}>
            <>

            <div className="px-3">
                    <BackButton />
                </div>
     
        <div className='mb-3'></div>

        <Box sx={{ p: 0, maxWidth: { sm: `26.56rem` }, marginLeft: 'auto', marginRight: 'auto' }}>
        <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                <div className="px-3">
                    
                    
                        <Fragment>
                            <div className="text-center py-4">
                                {" "}
                                <img src="/img-create-password.svg" alt="Recover password" />
                            </div>
                            
                            <div className="text-center">
                                <h1 className="h3 font-weight-bold">Confirm</h1>
                                <p>Please enter the 4-digit code just sent to <br/>{session?.currentUser?.email}</p>
                            </div>
                            <div className="mt-5 row d-flex justify-content-center">
                                <VerificationInput 
                                length={4} 
                                value={getValues('code')}
                                onChange={(val :string)=>{ console.log(val); setCode(val); setValue('code',val)}} />
                            </div>
                        </Fragment>
                    
                </div>
                <div className="mt-5 px-2 pb-4">
                    
                    <div style={{textAlign: 'center'}}>
                        <button type='submit' className="btn btn-primary btn-block rounded-lg" disabled={getValues('code')?.length < 4 ? true : false}>
                            Confirm
                        </button>
                        <button className="btn btn-link text-primary btn-inline" onClick={() => stopTimer()}>Change Number</button>
                        <button className="btn btn-link text-primary btn-inline" onClick={() => sendConfirmCode()}>Resend code in {time}</button>
                    </div>
                    
                </div>
                </form>
            </div>
        </Box>  

            </>
        </Layout>
    );
}

const mapStateToProps = (state: any) => ({ session: state.session })

export default connect(mapStateToProps)(VerificationEmail);