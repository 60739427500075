import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../app/store';
import { addBankingVerification, getCurrentUser, getLogin, getSignup, sendEmailCode, sendMobileCode, verifyEmailCode, verifyMobileCode } from '../../services/users-services';
import { localStorageKeys, removeAccessToken, setAccessToken } from '../../services/utils';

export interface SessionState {
  status: 'idle' | 'loading' | 'failed';
isBusy : boolean;
  loginResult : any;
  signupResult : any;
  currentUser : any;
  logoutResult : any;
  sendMobileCode: any;
  verifyMobileCode : any;
  sendEmailCode: any;
  verifyEmailCode : any;
  bankingVerification: any;
}

const initialState: SessionState = {
  status: 'idle',
isBusy: false,
  loginResult : null,
  signupResult : null,
  currentUser : null,
  logoutResult : null,
  sendMobileCode : null,
  verifyMobileCode: null,
  sendEmailCode : null,
  verifyEmailCode: null,
  bankingVerification : null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loginAsync = createAsyncThunk(
  'session/login',
  async (data: any) => {
    const response = await getLogin(data);
    return response;
  }
);

export const signupAsync = createAsyncThunk(
  'session/signup',
  async (data: any) => {
    const response = await getSignup(data);
    return response;
  }
);

export const getCurrentUserAsync = createAsyncThunk(
  'session/currentUser',
  async () => {
    const response = await getCurrentUser();
    return response;
  }
);

export const sendMobileCodeAsync = createAsyncThunk(
  'session/sendMobileCode',
  async (data : any) => {
    const response = await sendMobileCode(data);
    return response;
  }
);

export const verifyMobileCodeAsync = createAsyncThunk(
  'session/verifyMobileCode',
  async (data : any) => {
    const response = await verifyMobileCode(data);
    return response;
  }
);

export const sendEmailCodeAsync = createAsyncThunk(
  'session/sendEmailCode',
  async (data : any) => {
    const response = await sendEmailCode(data);
    return response;
  }
);

export const verifyEmailCodeAsync = createAsyncThunk(
  'session/verifyEmailCode',
  async (data : any) => {
    const response = await verifyEmailCode(data);
    return response;
  }
);

export const addBankingVerificationAsync = createAsyncThunk(
  'session/addBankingVerification',
  async (data : any) => {
    const response = await addBankingVerification(data);
    return response;
  }
);

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    logout: (state, action: PayloadAction<boolean>) => {
      state.currentUser = null;
      state.loginResult = null;
      state.signupResult = null;
      state.logoutResult = action.payload;
      setAccessToken('');
      removeAccessToken();
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status = 'loading';
        state.isBusy = true;
      })
      .addCase(loginAsync.rejected, (state) => {
        state.status = 'failed';
        state.isBusy = false;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        setAccessToken(action.payload.data.token);
        state.status = 'idle';
        state.isBusy = false;
        state.loginResult = action.payload.data;
        state.currentUser = action.payload.data;
      })
      .addCase(getCurrentUserAsync.pending, (state) => {
        state.status = 'loading';
        state.isBusy = true;
      })
      .addCase(getCurrentUserAsync.rejected, (state) => {
        state.status = 'failed';
        state.isBusy = false;
        state.currentUser = null;
      })
      .addCase(getCurrentUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.isBusy = false;
        state.currentUser = action.payload.data;
      })
      .addCase(signupAsync.pending, (state) => {
        state.status = 'loading';
        state.isBusy = true;
      })
      .addCase(signupAsync.rejected, (state,action) => {
        state.status = 'failed';
        state.isBusy = false;
        
      })
      .addCase(signupAsync.fulfilled, (state, action) => {
        setAccessToken(action.payload.data.token);

        state.status = 'idle';
        state.isBusy = false;
        state.signupResult = action.payload.data;
        state.loginResult = action.payload.data;
        state.currentUser = action.payload.data;
      })
      .addCase(sendMobileCodeAsync.pending, (state) => {
        state.status = 'loading';
        state.isBusy = true;
      })
      .addCase(sendMobileCodeAsync.rejected, (state) => {
        state.status = 'failed';
        state.isBusy = false;
      })
      .addCase(sendMobileCodeAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.isBusy = false;
        state.sendMobileCode = action.payload;
      })
      .addCase(verifyMobileCodeAsync.pending, (state) => {
        state.status = 'loading';
        state.isBusy = true;
      })
      .addCase(verifyMobileCodeAsync.rejected, (state) => {
        state.status = 'failed';
        state.isBusy = false;
        
      })
      .addCase(verifyMobileCodeAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.isBusy = false;
        state.verifyMobileCode = action.payload;
      })

      .addCase(sendEmailCodeAsync.pending, (state) => {
        state.status = 'loading';
        state.isBusy = true;
      })
      .addCase(sendEmailCodeAsync.rejected, (state) => {
        state.status = 'failed';
        state.isBusy = false;
      })
      .addCase(sendEmailCodeAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.isBusy = false;
        state.sendEmailCode = action.payload;
      })
      .addCase(verifyEmailCodeAsync.pending, (state) => {
        state.status = 'loading';
        state.isBusy = true;
      })
      .addCase(verifyEmailCodeAsync.rejected, (state) => {
        state.status = 'failed';
        state.isBusy = false;
        
      })
      .addCase(verifyEmailCodeAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.isBusy = false;
        state.verifyEmailCode = action.payload;
      })
      .addCase(addBankingVerificationAsync.pending, (state) => {
        state.status = 'loading';
        state.isBusy = true;
      })
      .addCase(addBankingVerificationAsync.rejected, (state) => {
        state.status = 'failed';
        state.isBusy = false;
      })
      .addCase(addBankingVerificationAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.isBusy = false;
        state.bankingVerification = action.payload;
      });
  },
});

export const { logout } = sessionSlice.actions;

export default sessionSlice.reducer;
