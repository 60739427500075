import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../app/store';
import { applicationList, submitApplication, startNewApplicationOrGetLast } from '../../services/application-services';

export interface ApplicationState {
  value: number;
  status: 'idle' | 'loading' | 'failed';
  isBusy : boolean;
  currentApplication : any;
  applications : [];
  submitResult : any;
}

const initialState: ApplicationState = {
  value: 0,
  status: 'idle',
isBusy: false,
  currentApplication : null,
  applications : [],
  submitResult : null,
};



export const startNewApplicationOrGetLastAsync = createAsyncThunk(
  'application/newApplication',
  async (data: any) => {
    const response = await startNewApplicationOrGetLast(data);
    
    return response;
  }
);

export const listApplicationAsync = createAsyncThunk(
  'application/list',
  async (data: any) => {
    const response = await applicationList(null);
    return response;
  }
);

export const submitApplicationAsync = createAsyncThunk(
  'application/submitApplication',
  async (data: any) => {
    const response = await submitApplication(data);
    
    return response;
  }
);







export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(listApplicationAsync.pending, (state) => {
      state.status = 'loading';
        state.isBusy = true;
    })
    .addCase(listApplicationAsync.rejected, (state) => {
      state.status = 'failed';
        state.isBusy = false;
    })
    .addCase(listApplicationAsync.fulfilled, (state, action) => {
      state.status = 'idle';
        state.isBusy = false;
      state.applications = action.payload;
      
    })
    .addCase(startNewApplicationOrGetLastAsync.pending, (state) => {
      state.status = 'loading';
        state.isBusy = true;
    })
    .addCase(startNewApplicationOrGetLastAsync.rejected, (state) => {
      state.status = 'failed';
        state.isBusy = false;
    })
    .addCase(startNewApplicationOrGetLastAsync.fulfilled, (state, action) => {
      state.status = 'idle';
        state.isBusy = false;
      state.currentApplication = action.payload;
    })
    .addCase(submitApplicationAsync.pending, (state) => {
      state.status = 'loading';
        state.isBusy = true;
    })
    .addCase(submitApplicationAsync.rejected, (state) => {
      state.status = 'failed';
        state.isBusy = false;
    })
    .addCase(submitApplicationAsync.fulfilled, (state, action) => {
      state.status = 'idle';
        state.isBusy = false;
      state.submitResult = action.payload;
    });

    

  },
});



export default applicationSlice.reducer;

