import React, { FormEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
//import styles from './Login.module.css';
import { loginAsync, sessionSlice } from './sessionSlice';

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../components/layout/layout';
import { Title } from '@mui/icons-material';
import { Box } from '@mui/system';
import { FaCheck } from 'react-icons/fa'
import BackButton from '../../components/backbutton/back-button';

const RecoverPassword = (session: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [isBusy, setIsBusy] = useState(true);
    const [resetPassword, setResetPassword] = useState(false)

    return (
        <Layout headerLogo={true} logoCenter={true} back={true}  session={session} dark={false} topbar={false} nav={false} checkLogin={false} isBusy={session.session.isBusy}>
            
            <>
            <div className="px-3">
                    <BackButton />
                </div>
            
            <Box sx={{ p: 0, maxWidth: { sm: `26.56rem` }, marginLeft: 'auto', marginRight: 'auto' }}>

{/* Recover Password  */}

<div className="px-3">
        {!resetPassword ?
          <>
            <div className="text-center py-4"> <img src="/img-create-password.svg" alt="Recover password" /></div>
            <h1 className="h3">Recovery Password</h1>
            <p>Enter your email address and we'll send you a code to reset your password.</p>

            <div className="mt-5">
              <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email address" />
            </div>

          </>
          :
          <>
            <div className="text-center py-4"> <img src="/img-create-password.svg" alt="Create password on money moves" /></div>
            <h1 className="h3 font-weight-bold">Reset Password</h1>
            <p>Choose a secure password that will be easy for you to remember.</p>

            <div className="mt-5">
              <input type="password" className="form-control" id="exampleInputPassword" aria-describedby="passwordHelp" placeholder="New Password" />
            </div>

            <ul className="list-unstyled mt-3">
              <li className="py-1"><span className="text-success"><FaCheck /></span> Has at least 8 characters</li>
              <li className="py-1"><span className="text-muted"><FaCheck /></span> Has an uppercase letter or symbol</li>
              <li className="py-1"><span className="text-muted"><FaCheck /></span> Has a number</li>
            </ul>
          </>
        }
      </div>

      {/* Create Password  */}
      <div className="mt-auto p-4 text-center">
        {!resetPassword ?
          <div>
            <small className="px-3">By using our mobile app, you agree to our Terms of Use and Privacy Policy</small>
            <button
              className="btn btn-primary py-3 btn-block my-2"
              style={{ borderRadius: '10rem' }}
              onClick={() => setResetPassword(!resetPassword)}>Send me a code</button>

          </div>

          :
          <button className="btn btn-primary py-3 btn-block " style={{ borderRadius: '10rem' }}>
            <Link to="/">
              <a className="text-white">Save Password</a>
            </Link>
          </button>
        }

      </div>

            </Box>
</>
            
        </Layout>
    );
}

const mapStateToProps = (state: any) => ({ session: state.session })

export default connect(mapStateToProps)(RecoverPassword);