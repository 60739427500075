import client from './client';
import { generateApiEndpoint } from './utils';
export async function submitApplication(data : any) {
  
  const endpoint = generateApiEndpoint('api/applicant/submit/'+ data.application_id);
  const response = await client.post(endpoint,  data);

  return response.data;
}

export async function startNewApplicationOrGetLast(data : any) {
  
  const endpoint = generateApiEndpoint('api/applicant/applications');
  const response = await client.post(endpoint,  data);

  return response.data;
}

export async function applicationList(data : any) {
  
  const endpoint = generateApiEndpoint('api/applicant/applications-list');
  const response = await client.get(endpoint);

  return response.data;
}





export function fetchCount(amount = 1) {
  return new Promise<{ data: number }>((resolve) =>
    setTimeout(() => resolve({ data: amount }), 500)
  );
}