import React, { FormEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
//import styles from './Login.module.css';
import { loginAsync, sessionSlice } from './sessionSlice';

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../components/layout/layout';
import { NavLink } from 'react-router-dom';
import { Box, maxWidth, width } from '@mui/system';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Alert, FormControl, InputLabel, Snackbar,TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';


type LoginValues = {
    username: string;
    password: string;
};

const Login = (session: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
        setValue,
        setError,
        clearErrors,
        trigger
    } = useForm<LoginValues>({
        mode: 'all',
        reValidateMode: 'onChange',
    });


    const [isBusy, setIsBusy] = useState(true);

    setTimeout(function () { setIsBusy(false) }, 1000);

    const [withEmail, isWithEmail] = useState(false);
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    const [showWarning, setShowWarning] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [isSending, setIsSending] = useState(false);

    const getPassword = (event: any) => {
        setPassword(event.target.value);
    };

    const getUsername = (event: any) => {
        setUsername(event.target.value);
    };

    const btnStatus = () => {
        const user = username.length >= 8;
        const pass = password.length >= 8;

        return user && pass ? false : true;
    };


    const onSubmit: SubmitHandler<LoginValues> = async (data) => {
        
        setIsSending(true);
        const { username, password } = data;
        const result = await dispatch(loginAsync({ email: username, password: password }));

        if (result.payload.data.error != null) {
            setWarningMessage(result.payload.message);
            setShowWarning(true);
            setIsSending(false)
        }
        else
        {
            navigate('/');
        }
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowWarning(false);
    };




    return (
        <Layout headerLogo={true} logoCenter={true} back={true} topbar={false} session={session} dark={false} nav={false} checkLogin={false} isBusy={session.session.isBusy}>

            <Box sx={{ p: 0, maxWidth: { sm: `26.56rem` }, marginLeft: 'auto', marginRight: 'auto' }}>
                <Snackbar open={showWarning} autoHideDuration={2000} style={{ margin: '0 auto' }} onClose={handleClose}>
                    <Alert severity="warning" sx={{ width: '100%' }}>
                        {warningMessage}
                    </Alert>
                </Snackbar>
                <div className="py-5 text-center px-3">
                    <img src="/logo-moneymoves.svg" className="mt-4" />

                    <div className={withEmail ? "pt-4 pb-2" : "py-5"}>
                        <h1 className="h3">Sign In</h1>
                        <p>
                            Don't have an account?
                            <NavLink to="/sign-up">
                                Register
                            </NavLink>
                        </p>
                    </div>

                    
                    
                        

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box>
                            <FormControl fullWidth sx={{mb: 1}}>
                                <TextField {...register('username', { required: true })} style={{backgroundColor: '#fff'}}  size="small" type="email" label="Username or email" />
                            </FormControl>
                            <FormControl fullWidth sx={{mb: 1}}>
                                <TextField {...register('password', { required: true })}  style={{backgroundColor: '#fff'}} size="small" type="password" label="Password" />
                            </FormControl>
                           
                            </Box>
                            <div className="d-flex justify-content-end mb-4">
                                <NavLink to="/recover-password">
                                    Forgot Password
                                </NavLink>
                            </div>

                            <LoadingButton
                                type="submit"
                                className="btn btn-primary py-3 btn-block" style={{ borderRadius: "10rem" }}
                                loading={isSending}
                                loadingPosition="center"
                                variant='contained'
                                loadingIndicator="Loading..."
                            >
                                Sign In
                            </LoadingButton>



                        </form>

                        <p className="pt-4 text-muted">---- Or ----</p>
                    <button className="btn btn-light bg-white shadow btn-block  pl-1 py-3 mb-3" style={{ borderRadius: "10rem" }}>
                        <img src="/icon-google.svg" className="mr-2" alt="Sign in with Google" /> Sign In with Google
                    </button>
                    <button className="btn btn-light bg-white shadow btn-block pl-1 py-3 " style={{ borderRadius: "10rem" }}>
                        <img src="/icon-fb.svg" className="mr-2" alt="Sign in with Facebook" /> Sign In with Facebook
                    </button>
                    
                </div>
            </Box>
        </Layout>
    );
}

const mapStateToProps = (state: any) => ({ session: state.session })

export default connect(mapStateToProps)(Login);