import React, { FormEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
//import styles from './Login.module.css';
import { loginAsync, logout, sessionSlice } from './sessionSlice';

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../components/layout/layout';
import { Title } from '@mui/icons-material';
import { Box } from '@mui/material';

import { NavLink } from 'react-router-dom';
import { ProfileCard } from './components';
import { ProfileFeauture } from './components/profilefeature/profilefeature';

const Profile = (session: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [isBusy, setIsBusy] = useState(true);

    setTimeout(function(){setIsBusy(false)}, 1000);

    const handleLogut = (e : React.SyntheticEvent) =>{
    
        const logoutBundle = async ()=>{
            dispatch(logout(false));
        }
    
        logoutBundle().then(()=>{
            navigate('/sign-in');
        });
      }

    useEffect(() => {
       
    }, [session]);

    return (
        <Layout headerLogo={true} logoCenter={true} back={true} topbar={true} session={session} dark={true} nav={true} checkLogin={false} isBusy={session.session.isBusy}>
            <>
            <div className="bg-gradient-dark text-white pb-5">
                <div className="px-3 text-center">
                    <ProfileCard />
                </div>
                <div className="px-5 pt-3 d-flex align-items-center">
                    {/* <span>Things to do</span>
                    <span className="ml-auto bg-white text-muted rounded-lg px-2 ">1 of 3</span> */}
                </div>
            </div>

            <div className="bg-light px-3 mt-5 shadow pb-bottom-app">
                <div className="card shadow-sm mt-neg-lg rounded-lg border-0 mb-4">
                    <div className="card-body d-flex">
                        <div className="mr-3">
                            <img src="/icon-verify-identity.svg" alt="" />
                        </div>
                        <div>
                            <strong>Verify your identity</strong>
                            <p>
                                <small>Take a government ID & selfie photo to get verified</small>
                            </p>
                            <NavLink to="/verify" className="btn btn-primary rounded-lg px-3 py-2">
                                Verify now
                            </NavLink>

                            <Link to="" onClick={handleLogut} className="btn btn-primary rounded-lg px-3 py-2">
                                Log out
                            </Link>
                        </div>
                    </div>
                </div>

                <ProfileFeauture cards={["Invite Friends", "Settings"]} title="More Features" />
            </div>
            </>
        </Layout>
    );
}

const mapStateToProps = (state: any) => ({ session: state.session })

export default connect(mapStateToProps)(Profile);