import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';

import { Layout } from '../../components/layout/layout';
import { useNavigate } from 'react-router-dom';
import { Box, Alert, Snackbar, Select, TextField, InputLabel, FormControl, MenuItem, Modal, Hidden } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { startNewApplicationOrGetLastAsync, submitApplicationAsync } from './applicationSlice';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import NumberFormat from 'react-number-format';
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
  } from '@material-ui/pickers';
  import MomentUtils from '@date-io/moment';

import CreditScoreCard from '../../components/creditscorecard/creditscorecard';
import MuiPhoneNumber from 'material-ui-phone-number';

import AddressSearch from 'react-loqate';
// to use the default styles for the default components
//import 'react-loqate/dist/react-loqate.cjs.development.css';



const terms = [
    { label: "1 Year", value: "1" }, 
    { label: "2 Years", value: "2" }, 
    { label: "3 Years", value: "3" }, 
    { label: "4 Years", value: "4" },
    { label: "5 Years", value: "5" },
]
const amortizations = [
    { label: "1 Year", value: "1" }, 
    { label: "2 Years", value: "2" }, 
    { label: "3 Years", value: "3" }, 
    { label: "4 Years", value: "4" },
    { label: "5 Years", value: "5" },
]
const maritalStatuses = [
    { label: "Married", value: "married" }, 
    { label: "Single", value: "single" }, 
    { label: "Divorced", value: "divorced" }, 
    { label: "Separated", value: "separated" },
]

const residentalStatuses = [
    { label: "Own", value: "own" }, 
    { label: "Rent", value: "rent" }, 
    { label: "Living with parents", value: "Living with parents" }, 
]

const residentalOwnTypes = [
    { label: "I/We are on Title", value: "I/We are on Title" }, 
    { label: "Not on Title", value: "Not on Title" }, 
]

const employmentStatuses = [
    { label: "Employed full time", value: "Employed full time" }, 
    { label: "Employed part time", value: "Employed part time" }, 
    { label: "Student", value: "Student" },
    { label: "Retired", value: "Retired" }, 
    { label: "Other", value: "Other" }, 
]

const provinces = [
    { label: "Alberta", value: "AB" }, 
    { label: "British Columbia", value: "BC" }, 
    { label: "Manitoba", value: "MB" }, 
    { label: "New Brunswick", value: "NB" }, 
    { label: "Newfoundland and Labrador", value: "NL" }, 
    { label: "Northwest Territories", value: "NT" }, 
    { label: "Nova Scotia", value: "NS" }, 
    { label: "Nunavut", value: "NU" }, 
    { label: "Prince Edward Island", value: "PE" }, 
    { label: "Quebec", value: "QC" }, 
    { label: "Saskatchewan", value: "SK" }, 
    { label: "Yukon", value: "YT" }, 
    { label: "Ontorio", value : "ON"}
]

export const optionsCompanyType = [
  { value: '101', label: 'Architecture/Science/Engineering' },
  { value: '102', label: 'Arts/Design' },
  { value: '103', label: 'Automobile/Mechanic/Repair' },
  { value: '104', label: 'Community/Social Service' },
  { value: '105', label: 'Computer/Information Technology' },
  { value: '106', label: 'Construction/Contractor' },
  { value: '107', label: 'Education/Training/Library' },
  { value: '108', label: 'Farming/Fishing/Forestry' },
  { value: '109', label: 'Finance/Accounting' },
  { value: '110', label: 'Government Employee' },
  { value: '111', label: 'Healthcare/Personal Care/Medical' },
  { value: '112', label: 'Landscaping/Property Maintenance' },
  { value: '113', label: 'Legal/Compliance' },
  { value: '114', label: 'Long-Term Disability' },
  { value: '115', label: 'Management/Professional/Business Services' },
  { value: '116', label: 'Manufacturing/Production' },
  { value: '117', label: 'Media/Communications' },
  { value: '118', label: 'Military/Law Enforcement/Security' },
  { value: '119', label: 'Mining/Logging' },
  { value: '120', label: 'Native Band' },
  { value: '121', label: 'Oil/Gas Services' },
  { value: '122', label: 'Other' },
  { value: '123', label: 'Real Estate/Realtor' },
  { value: '124', label: 'Restaurant/Hospitality/Food Service' },
  { value: '125', label: 'Retail Trade' },
  { value: '126', label: 'Retired/Pension' },
  { value: '127', label: 'Sales/Marketing' },
  { value: '128', label: 'Self-Employed/Consultant' },
  { value: '129', label: 'Skilled Trades/Labourer' },
  { value: '130', label: 'Transportation/Logistics' },
];

const titles = [{ label: "Select", value: "" },{ label: "Mrs.", value: "Mrs." }, { label: "Ms.", value: "Ms." }, { label: "Miss", value: "Miss" }, { label: "Mr.", value: "Mr." },]



type LoanValues = {
    application_id : number;
    //Loan Detail
    amount : number;
    type : string;
    creditscore : string;
    term: string,
    amortization : string;
    //General Information
    date_of_birth: string;
    email_address: string;
    title: string;
    first_name: string;
    last_name: string;
    home_phone: string;
    marital_status: string,
    mobile_phone: string;
    type_of_government_id: string;
    government_issued_id: string;
    //Address
    address: string;
    city: string;
    postal_code: string;
    province: string;
    province_name : string;
    years_at_current_location: number;
    months_at_current_location: number;
    //Home ownership
    monthly_payment: number;
    outstanding_mortgage_balance: number;
    payment_made_to: string;
    residential_status: string;
    title_of_property: string;
    //Employment 
    company_address  : string; 
    company_city : string; 
    company_months_of_employment : number;
    company_name : string; 
    company_position : string;
    company_postal_code : string;
    company_province: string; 
    company_province_name : string;
    company_salary : number; 
    company_salary_type : string;
    company_telephone : string;
    company_type_of_business : string;
    company_years_of_employment: number; 
    employment_status : string;
};

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }

  

const MoneyFormatCustom = React.forwardRef<NumberFormat<number>, CustomProps>(
    function MoneyFormatCustom(props, ref) {
      const { onChange, ...other } = props;
  
      return (
        <NumberFormat
          {...other}
          getInputRef={ref}
          onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
          thousandSeparator
          isNumericString
          prefix="$"
        />
      );
    },
  );

  const NumberFormatCustom = React.forwardRef<NumberFormat<number>, CustomProps>(
    function NumberFormatCustom(props, ref) {
      const { onChange, ...other } = props;
  
      return (
        <NumberFormat
          {...other}
          getInputRef={ref}
          onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
          thousandSeparator={false}
          isNumericString
          prefix=""
        />
      );
    },
  );

  const AddressSearchInput = (props : any): JSX.Element => {
    return (
      <TextField
      label="Address"
      style={{backgroundColor: '#fff', width: '100%' }} 
        placeholder={'start typing your address or postcode'}
        autoComplete="chrome-off"
        {...props}
        size="small"
      />
    );
  };

  const CompanyAddressSearchInput = (props : any): JSX.Element => {
    return (
      <TextField
      label="Company Address"
      style={{backgroundColor: '#fff', width: '100%' }} 
        placeholder={'start typing your address or postcode'}
        autoComplete="chrome-off"
        {...props}
        size="small"
      />
    );
  };

  const styleModal = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };


const NewApplication = (session: any, application : any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [currentApplication,setCurrentApplication] = useState({id: ''});
    const [isBusy, setIsBusy] = useState(true);
    const [showWarning, setShowWarning] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [cardStatus, setCardStatus] = useState({ Good: false, Average: true, Poor: false });
    const [selectedDate, handleDateChange] = useState<Date>(new Date());
    const [residentialStatus,SetResidentialStatus] = useState('');
    const [openMobilePhoneModal,setOpenMobilePhoneModal] = useState(false);
    const [applicationId,setApplicationId] = useState('');


     const handleOpenMbilePhoneModal = () => {
        setOpenMobilePhoneModal(true);
      };
      const handleCloseMbilePhoneModal = () => {
        setOpenMobilePhoneModal(false);
      };

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
        setValue,
        setError,
        clearErrors,
        trigger,
        getValues,
    } = useForm<LoanValues>({
        mode: 'all',
        reValidateMode: 'onChange',
    });

    useEffect(() => {
        //General Information
        setValue('email_address',session?.session?.currentUser?.email);
        setValue('first_name',session?.session?.currentUser?.names.first_name);
        setValue('last_name',session?.session?.currentUser?.names.last_name);
        setValue('mobile_phone',session?.session?.currentUser?.mobile);
        
        trigger('amount');
        trigger('term')
        trigger('amortization');

        const getApplication = async () => {
            const appResult = await (await dispatch(startNewApplicationOrGetLastAsync({}))).payload;
            setCurrentApplication(appResult);


            


            
            setValue('application_id', appResult.id);
        };

        getApplication();

        
    }, []);



    const onSubmit: SubmitHandler<LoanValues> = async (data) => {

        setIsSending(true);

        await dispatch(submitApplicationAsync(data));


    }

    const changeStatus = (data : string) => {
        const copyState = { ...cardStatus };
        const keys = Object.keys(copyState);
        keys.forEach((key : string) => {
            if(key == 'Good')
                copyState.Good = false;
            if(key == 'Average')
                copyState.Average = false;
            if(key == 'Poor')
                copyState.Poor = false;
        });
        if(data == 'Good')
            copyState.Good = true;
        if(data == 'Average')
            copyState.Average = true;
        if(data == 'Poor')
            copyState.Poor = true;
        setCardStatus(copyState);
    };



    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowWarning(false);
    };


    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = (check : boolean) => {

        if(check) setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep == 0 ? 0 : prevActiveStep - 1);
    };

    const isLoanDetailFilled = () =>{
        
        return errors.amount  != null || errors.term  != null || errors.amortization != null ? false : true;
    }

    const isGeneralInfoFilled = () =>{

        
        return errors.date_of_birth != null || 
        errors.email_address  != null ||
        errors.title != null ||
        errors.first_name != null ||
        errors.last_name  != null ||
        errors.home_phone != null ||
        errors.marital_status != null ||
        errors.mobile_phone  != null ? false : true;
    }

    const isAddressFilled = () =>{
        return errors.address != null ||
        errors.city != null ||
        errors.postal_code != null ||
        errors.province  != null ||
        errors.years_at_current_location != null ||
        errors.months_at_current_location  != null ? false : true;
    }

    const isHomeOwnershipFilled = () =>{
        return errors.monthly_payment != null ||
        errors.outstanding_mortgage_balance != null ||
        errors.payment_made_to != null ||
        errors.residential_status != null ||
        errors.title_of_property != null ? false :true;
    }

    const isEmploymentFilled = () =>{
        return errors.company_address != null ||
        errors.company_city != null ||
        errors.company_months_of_employment != null ||
        errors.company_name != null ||
        errors.company_position != null ||
        errors.company_postal_code != null ||
        errors.company_province != null ||
        errors.company_salary != null ||
        errors.company_salary_type != null ||
        errors.company_telephone != null ||
        errors.company_type_of_business != null ||
        errors.company_years_of_employment != null ||
        errors.employment_status != null ? false : true
    }

    setTimeout(function () { setIsBusy(false) }, 1000);

    return (
        <Layout headerLogo={true} logoCenter={true} back={true} topbar={true} session={session} dark={true} nav={true} checkLogin={true} isBusy={session.session.isBusy}>

            <Box sx={{ p: 0, pl: 2, mt: 3, mb: 30, maxWidth: { sm: `26.56rem`, xl: `52rem` },  }}>

                <Snackbar open={showWarning} autoHideDuration={2000} style={{ margin: '0 auto' }} onClose={handleClose}>
                    <Alert severity="warning" sx={{ width: '100%' }}>
                        {warningMessage}
                    </Alert>
                </Snackbar>
                {session.session.currentUser.email_verified != true && session.session.currentUser.mobile_verified != true ? (<>
                    
                <Typography variant="h4" color="red">
                Warning!
                </Typography>

                <Typography variant="subtitle1">
                    You have to <a href="/verify/email">verify your e-mail address</a> to submit loan application.
                </Typography>
            
                </>) : (


<Stepper activeStep={activeStep} orientation="vertical">
                        <Step>
                        
                            <StepLabel>
                                <Typography variant='subtitle1' style={{fontWeight: 'bold'}}>Loan Detail</Typography>
                            </StepLabel>
                            <StepContent>
                            <form action="" id="loanForm" onSubmit={handleSubmit(onSubmit)}>
                                
                                <input type="hidden" {...register('application_id')} defaultValue={currentApplication.id}></input>
                                <Typography variant='h4' align='center'>Configure your loan</Typography>
                                <Typography variant='subtitle1' align='center'>Set the loan according to your needs</Typography>
                                <Box sx={{ mb: 2, mt:2 }}>
                                     
                                    <FormControl fullWidth sx={{mb: 1}}>
                                        <TextField
                                            {...register('amount', { required: true })}
                                            error={errors.amount ? true : false}
                                            label="Amount"
                                            style={{backgroundColor: '#fff', textAlign: 'center', maxWidth: '50%', marginLeft: 'auto', marginRight: 'auto' }} 
                                            InputProps={{
                                            inputComponent: MoneyFormatCustom as any,
                                            
                                            }}
                                            defaultValue={getValues('amount')}
                                            size="small"
                                        />
                                    </FormControl>

                                    <Typography variant='subtitle1' display={'block'} align='center' mt={2} mb={2}>Estimated credit score</Typography>
                                    <Box style={{textAlign: 'center'}} alignItems={'center'} mb={2}>
                                        
                                        
                                        <Box display={'inline-block'} sx={{mr: 1}}>
                                            <label htmlFor="good">
                                                <CreditScoreCard active={cardStatus.Good} click={changeStatus} name="Good" interestRate={14.99} monthlyPayment={299.99} />
                                            </label>
                                            <input {...register("creditscore")}  type="radio" value="good" id="good" hidden={true}/>
                                        </Box>
                                        <Box display={'inline-block'} sx={{mr: 1}}>
                                            <label htmlFor="average">
                                                <CreditScoreCard active={cardStatus.Average} click={changeStatus} name="Average" interestRate={19.99} monthlyPayment={349.99} />
                                            </label>
                                            <input {...register("creditscore")} type="radio" value="average" id="average" defaultChecked hidden={true}/>
                                        </Box>
                                        <Box display={'inline-block'}>
                                            <label htmlFor="poor">
                                                <CreditScoreCard active={cardStatus.Poor} click={changeStatus} name="Poor" interestRate={24.99} monthlyPayment={399.99} />
                                            </label>
                                            <input {...register("creditscore")} type="radio" value="poor" id="poor" hidden={true}/>
                                        </Box>
                                    </Box>

                                    <FormControl fullWidth sx={{mb: 1}}>
                                        <InputLabel id="demo-select-small">Program</InputLabel>
                                        <Select 
                                            {...register('type', { required: true })} 
                                            error={errors.type ? true : false}
                                            style={{backgroundColor: '#fff' }} 
                                            defaultValue={getValues('type')}
                                            onChange={(data: any) =>{ 
                                                setValue('type', data.target.value);
                                            }}
                                            size='small'
                                            >
                                            
                                            {session?.session?.currentUser?.options_program.map((item : any) =>(
                                                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{mb: 1, width: '49%', mr: '2%'}}>
                                        <InputLabel id="demo-select-small">Term</InputLabel>
                                        <Select 
                                            {...register('term', { required: true })} 
                                            error={errors.term ? true : false}
                                            style={{backgroundColor: '#fff' }} 
                                            defaultValue={getValues('term')}
                                            onChange={(data : any) =>{ 
                                                console.log(data.target.value);
                                                setValue('term', data.target.value);
                                            }}
                                            size='small'
                                            >
                                            
                                            {terms.map(item =>(
                                                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{mb: 1, width: '49%'}}>
                                        <InputLabel id="demo-select-small">Amortization</InputLabel>
                                        <Select 
                                            {...register('amortization', { required: true })} 
                                            error={errors.amortization ? true : false}
                                            style={{backgroundColor: '#fff' }} 
                                            defaultValue={getValues('amortization')}
                                            size='small'
                                            onChange={(data : any ) =>{ 
                                                setValue('amortization', data.target.value);
                                            }}
                                            >
                                            {amortizations.map(item =>(
                                                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <div>
                                        <Button hidden={activeStep == 0} onClick={handleBack} sx={{ float: 'left', mt: 1, mr: 1 }}>Back</Button>
                                        <Button variant="contained" type="submit" onClick={() => {handleNext(isLoanDetailFilled())}} sx={{float: 'right', mt: 1, mr: 1 }}>Continue</Button>
                                    </div>
                                </Box>
                                </form>
                            </StepContent>
                        </Step>
                        <Step>
                            <StepLabel>
                                <Typography variant='subtitle1' style={{fontWeight: 'bold'}}>General Information</Typography>
                            </StepLabel>
                            <StepContent>
                                <Typography></Typography>
                                <Box sx={{ mb: 2 }}>

                                    <FormControl fullWidth sx={{mb: 1}}>
                                        <TextField style={{backgroundColor: '#fff'}} size='small' disabled={true} label='Email' defaultValue={getValues('email_address')}/>
                                    </FormControl>
                                    <FormControl fullWidth size="small" sx={{mb: 1}}>
                                        <InputLabel id="demo-select-small">Title</InputLabel>
                                        <Select style={{backgroundColor: '#fff'}} {...register('title', { required: true })} 
                                        error={errors.title ? true : false}
                                        defaultValue={getValues('title')}
                                        onChange={(data : any ) =>{ 
                                            setValue('title', data.target.value);
                                        }}
                                        size='small'
                                        >
                                            {titles.map(item =>(
                                                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth sx={{mb: 1, minWidth: '49%'}}>
                                        <InputLabel id="demo-select-small">Marital Status</InputLabel>
                                        <Select 
                                            {...register('marital_status', { required: true })} 
                                            error={errors.marital_status ? true : false}
                                            style={{backgroundColor: '#fff' }} 
                                            defaultValue={getValues('marital_status')}
                                            onChange={(data : any ) =>{ 
                                                setValue('marital_status', data.target.value);
                                            }}
                                            size='small'
                                            >
                                            {maritalStatuses.map(item =>(
                                                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{mb: 1, width: '49%', mr: '2%'}}>
                                        <TextField style={{backgroundColor: '#fff'}} {...register('first_name', { required: true })} 
                                        error={errors.first_name ? true : false}
                                        defaultValue={getValues('first_name')} label="First Name" size='small'/>
                                    </FormControl>
                                    <FormControl sx={{mb: 1, width: '49%'}}>
                                        <TextField  style={{backgroundColor: '#fff'}}  {...register('last_name', { required: true })} 
                                        error={errors.last_name ? true : false}
                                        label="Last Name" size='small'/>
                                    </FormControl>

                                    <Typography variant='subtitle1' align='left' mb={1} mt={1}>Phone Numbers</Typography>

                                    <FormControl fullWidth sx={{mb: 1}}>
                                        <MuiPhoneNumber style={{backgroundColor: '#fff', marginLeft: '5px', marginBottom: '5px'}}  {...register('home_phone', { required: true })} 
                                        error={errors.home_phone ? true : false}
                                        value={getValues('home_phone')}
                                        label="Home Phone" size='small' onlyCountries={['ca']} defaultCountry={'ca'} countryCodeEditable={false} 
                                        onChange={(value : any) =>{ setValue('home_phone',value)}}/>
                                        
                                    </FormControl>
                                    <FormControl sx={{mb: 1, width: '68%', mr: '2%'}}>
                                        <MuiPhoneNumber disabled={session?.session?.currentUser?.mobile_verified} style={{backgroundColor: '#fff', marginLeft: '5px', marginBottom: '5px'}}  {...register('mobile_phone', { required: true })} 
                                        error={errors.mobile_phone ? true : false}
                                        value={getValues('mobile_phone')}
                                        label="Mobile Phone" size='small' 
                                        onlyCountries={['ca','tr']} 
                                        defaultCountry={'ca'}
                                        countryCodeEditable 
                                        onChange={(value : any) =>{ setValue('mobile_phone',value)}}
                                        />
                                        
                                    </FormControl>

                                    <FormControl sx={{mb: 1, width: '30%'}}>
                                       <Button onClick={()=> {handleOpenMbilePhoneModal()}}>Verify</Button>
                                       
                                    </FormControl>

                                    <Modal
                                        open={openMobilePhoneModal}
                                        onClose={handleCloseMbilePhoneModal}
                                        aria-labelledby="parent-modal-title"
                                        aria-describedby="parent-modal-description"
                                        >
                                        <Box sx={{ ...styleModal, width: 400 }}>
                                            <h2 id="parent-modal-title">Text in a modal</h2>
                                            <p id="parent-modal-description">
                                            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                                            </p>
                                            
                                        </Box>
                                    </Modal>

                                    

                                    <div>
                                        <Button hidden={activeStep == 0} onClick={handleBack} sx={{ float: 'left', mt: 1, mr: 1 }}>Back</Button>
                                        <Button variant="contained" type="submit" onClick={() => {handleNext(isGeneralInfoFilled())}} sx={{float: 'right', mt: 1, mr: 1 }}>Continue</Button>
                                    </div>
                                </Box>
                            </StepContent>
                        </Step>
                        <Step>
                            <StepLabel>
                                <Typography variant='subtitle1' style={{fontWeight: 'bold'}}>Current Address</Typography>
                            </StepLabel>
                            <StepContent>
                                <Typography></Typography>
                                <Box sx={{ mb: 2 }}>
                                <FormControl fullWidth sx={{mb: 1}}>
                                    <AddressSearch
                                        locale="en_GB"
                                        apiKey="FN54-ZW77-RT89-KE96"
                                        countries={['CA']}
                                        components={{ Input: AddressSearchInput }}
                                        classes={{ list: 'custom-list-style' }}
                                        onSelect={address => {

                                            console.log(address);
                                            setValue('address', address.Line1);
                                            setValue('postal_code', address.PostalCode);
                                            setValue('city', address.City);
                                            setValue('province', address.ProvinceCode);
                                            setValue('province_name', address.ProvinceName);
                                            
                                            
                                            
                                        }}
                                        debounce={0}
                                        />
                                </FormControl>   
                                <FormControl fullWidth sx={{mb: 1}}>
                                    <TextField style={{backgroundColor: '#fff'}} {...register('address', { required: true })} 
                                    error={errors.address ? true : false}
                                    label="Address Line" size='small'/>
                                </FormControl>
                                <FormControl sx={{mb: 1, width: '49%', mr: '2%'}}>
                                    <TextField style={{backgroundColor: '#fff'}} {...register('postal_code', { required: true })} 
                                    error={errors.postal_code ? true : false}  label="Postal Code" size='small'/>
                                </FormControl>
                                <FormControl  sx={{mb: 1, width: '49%'}}>
                                    <TextField style={{backgroundColor: '#fff'}} autoComplete='chrome-off' {...register('city', { required: true })} 
                                    error={errors.city ? true : false} label="City" size='small'/>
                                </FormControl>
                                <FormControl fullWidth sx={{mb: 1, minWidth: '49%'}}>
                                    <input type="hidden" {...register('province')}></input>
                                    <TextField label="Province Name" style={{backgroundColor: '#fff'}} autoComplete='chrome-off' {...register('province_name')} />
                                </FormControl>
                                <FormControl  sx={{mb: 1, width: '49%', mr: '2%'}}>
                                    <TextField style={{backgroundColor: '#fff'}} {...register('months_at_current_location', { required: true })} 
                                    error={errors.months_at_current_location ? true : false}
                                    defaultValue={getValues('months_at_current_location')} label="Months at current location" size='small'
                                    InputProps={{
                                        inputComponent: NumberFormatCustom as any,
                                        }}
                                    />
                                </FormControl>
                                <FormControl  sx={{mb: 1, width: '49%'}}>
                                    <TextField style={{backgroundColor: '#fff'}} {...register('years_at_current_location', { required: true })} 
                                    error={errors.years_at_current_location ? true : false}
                                    defaultValue={getValues('years_at_current_location')} label="Years at current location" size='small'
                                    InputProps={{
                                        inputComponent: NumberFormatCustom as any,
                                        }}
                                    />
                                </FormControl>
                                <div>
                                        <Button hidden={activeStep == 0} onClick={handleBack} sx={{ float: 'left', mt: 1, mr: 1 }}>Back</Button>
                                        <Button variant="contained" type="submit" onClick={() => {handleNext(isAddressFilled())}} sx={{float: 'right', mt: 1, mr: 1 }}>Continue</Button>
                                    </div>
                                </Box>
                            </StepContent>
                        </Step>
                        <Step>
                            <StepLabel>
                                <Typography variant='subtitle1' style={{fontWeight: 'bold'}}>Home Ownership</Typography>
                            </StepLabel>
                            <StepContent>
                                <Typography></Typography>
                                <Box sx={{ mb: 2 }}>
                                    <FormControl fullWidth sx={{mb: 1, minWidth: '49%'}}>
                                        <InputLabel id="demo-select-small">Residential Status</InputLabel>
                                        <Select 
                                            {...register('residential_status', { required: true })} 
                                            error={errors.residential_status ? true : false}
                                            style={{backgroundColor: '#fff' }} 
                                            onChange={(elm : any) => { SetResidentialStatus(elm.target.value)}}
                                            defaultValue={getValues('residential_status')}
                                            size='small'
                                            >
                                            {residentalStatuses.map(item =>(
                                                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {residentialStatus == 'own' && (
                                        <FormControl fullWidth sx={{mb: 1}} >
                                            <InputLabel id="demo-select-small">Title of Property</InputLabel>
                                            <Select 
                                                {...register('title_of_property', { required: true })} label="Title of Property"
                                                error={errors.title_of_property ? true : false}
                                                style={{backgroundColor: '#fff' }} 
                                                defaultValue={getValues('title_of_property')}
                                                size='small'
                                                >
                                                {residentalOwnTypes.map(item =>(
                                                    <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                            
                                        </FormControl>
                                    )}                
                                    
                                    <FormControl sx={{mb: 1, width: '49%', mr: '2%'}}>
                                        <TextField style={{backgroundColor: '#fff'}} {...register('outstanding_mortgage_balance', { required: true })} 
                                        error={errors.outstanding_mortgage_balance ? true : false}
                                        defaultValue={getValues('outstanding_mortgage_balance')} label="Outstanding mortgage balance" size='small'
                                        InputProps={{
                                            inputComponent: MoneyFormatCustom as any,
                                            }}
                                        />
                                    </FormControl>

                                    <FormControl  sx={{mb: 1, width: '49%'}}>
                                        <TextField style={{backgroundColor: '#fff'}} {...register('monthly_payment', { required: true })} 
                                        error={errors.monthly_payment ? true : false}
                                        defaultValue={getValues('monthly_payment')} label="Monthly Mortgage or Rental Payment" size='small'
                                        InputProps={{
                                            inputComponent: MoneyFormatCustom as any,
                                            }}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth sx={{mb: 1}}>
                                        <TextField style={{backgroundColor: '#fff'}} {...register('payment_made_to', { required: true })} 
                                        error={errors.payment_made_to ? true : false}
                                        defaultValue={getValues('payment_made_to')} label="Bank or Landlord Name *" size='small'/>
                                    </FormControl>


                                    
                                
                                    <div>
                                        <Button hidden={activeStep == 0} onClick={handleBack} sx={{ float: 'left', mt: 1, mr: 1 }}>Back</Button>
                                        <Button variant="contained" type="submit" onClick={() => {handleNext(isHomeOwnershipFilled())}} sx={{float: 'right', mt: 1, mr: 1 }}>Continue</Button>
                                    </div>
                                </Box>
                            </StepContent>
                        </Step>
                        <Step>
                            <StepLabel>
                                <Typography variant='subtitle1' style={{fontWeight: 'bold'}}>Employment</Typography>
                            </StepLabel>
                            <StepContent>
                                <Typography variant='subtitle1' align='left'>Position Details</Typography>
                                <Box sx={{ mb: 2 }}>

                                    <FormControl fullWidth sx={{mb: 1, minWidth: '49%'}}>
                                        <InputLabel id="demo-select-small">Employment Status</InputLabel>
                                        <Select 
                                            {...register('employment_status', { required: true })} 
                                            error={errors.employment_status ? true : false}
                                            style={{backgroundColor: '#fff' }} 
                                            defaultValue={getValues('employment_status')}
                                            size='small'
                                            >
                                            {employmentStatuses.map(item =>(
                                                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth sx={{mb: 1}}>
                                        <TextField style={{backgroundColor: '#fff'}} {...register('company_position', { required: true })} 
                                        error={errors.company_position ? true : false}
                                        defaultValue={getValues('company_position')} label="Position" size='small'/>
                                    </FormControl>
                                    <FormControl  fullWidth sx={{mb: 1}}>
                                        <TextField style={{backgroundColor: '#fff'}} {...register('company_salary', { required: true })} 
                                        error={errors.company_salary ? true : false}
                                        defaultValue={getValues('company_salary')} label="Monthly Income" size='small'
                                        InputProps={{
                                            inputComponent: MoneyFormatCustom as any,
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl  sx={{mb: 1, width: '49%', mr: '2%'}}>
                                        <TextField style={{backgroundColor: '#fff'}} {...register('company_years_of_employment', { required: true })} 
                                        error={errors.company_years_of_employment ? true : false}
                                        defaultValue={getValues('company_years_of_employment')} label="Years at current location" size='small'
                                        InputProps={{
                                            inputComponent: NumberFormatCustom as any,
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl  sx={{mb: 1, width: '49%'}}>
                                        <TextField style={{backgroundColor: '#fff'}} {...register('company_months_of_employment', { required: true })} 
                                        error={errors.company_months_of_employment ? true : false}
                                        defaultValue={getValues('company_months_of_employment')} label="Months at current location" size='small'
                                        InputProps={{
                                            inputComponent: NumberFormatCustom as any,
                                            }}
                                        />
                                    </FormControl>

                                    <Typography variant='subtitle1' align='left'>Company Details</Typography>
                                    <FormControl fullWidth sx={{mb: 1}}>
                                        <TextField style={{backgroundColor: '#fff'}} {...register('company_name', { required: true })} 
                                        error={errors.company_name ? true : false}
                                        defaultValue={getValues('company_name')} label="Company Name" size='small'/>
                                    </FormControl>
                                    <FormControl fullWidth sx={{mb: 1, minWidth: '49%'}}>
                                        <InputLabel id="demo-select-small">Type of Business</InputLabel>
                                        <Select 
                                            {...register('company_type_of_business', { required: true })} 
                                            error={errors.company_type_of_business ? true : false}
                                            style={{backgroundColor: '#fff' }} 
                                            size='small'
                                            defaultValue={getValues('company_type_of_business')}
                                            >
                                            {optionsCompanyType.map(item =>(
                                                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Typography variant='subtitle1' align='left'>Company Address</Typography>
                                    <FormControl fullWidth sx={{mb: 1}}>
                                    <AddressSearch
                                        locale="en_GB"
                                        apiKey="FN54-ZW77-RT89-KE96"
                                        countries={['CA']}
                                        components={{ Input: CompanyAddressSearchInput }}
                                        classes={{ list: 'custom-list-style' }}
                                        onSelect={address => {
                                            setValue('company_address', address.Line1);
                                            setValue('company_postal_code', address.PostalCode);
                                            setValue('company_city', address.City);
                                            setValue('company_province', address.ProvinceCode);
                                            setValue('company_province_name', address.ProvinceName);
                                        }}
                                        debounce={0}
                                        />
                                </FormControl>   

                                <FormControl fullWidth sx={{mb: 1}}>
                                    <TextField style={{backgroundColor: '#fff'}} {...register('company_address', { required: true })} 
                                    error={errors.company_address ? true : false}
                                    label="Address Line" size='small'/>
                                </FormControl>
                                <FormControl sx={{mb: 1, width: '49%', mr: '2%'}}>
                                    <TextField style={{backgroundColor: '#fff'}} {...register('company_postal_code', { required: true })} 
                                    error={errors.company_postal_code ? true : false}  label="Postal Code" size='small'/>
                                </FormControl>
                                <FormControl  sx={{mb: 1, width: '49%'}}>
                                    <TextField style={{backgroundColor: '#fff'}} autoComplete='chrome-off' {...register('company_city', { required: true })} 
                                    error={errors.company_city ? true : false} label="City" size='small'/>
                                </FormControl>
                                <FormControl fullWidth sx={{mb: 1, minWidth: '49%'}}>
                                    <input type="hidden" {...register('company_province')}></input>
                                    <TextField label="Province Name" style={{backgroundColor: '#fff'}} autoComplete='chrome-off' {...register('company_province_name')} />
                                </FormControl>
                                    

                                    <FormControl sx={{mb: 1, width: '49%', mr: '2%'}}>
                                        <MuiPhoneNumber style={{backgroundColor: '#fff', marginLeft: '5px', marginBottom: '5px'}} {...register('company_telephone', { required: true })} 
                                        error={errors.company_telephone ? true : false}
                                        value={getValues('company_telephone')}
                                        label="Company Phone" size='small' onlyCountries={['ca']} defaultCountry={'ca'} countryCodeEditable onChange={(value : any) =>{ setValue('company_telephone',value)}}/>
                                    </FormControl>


                                    <div>
                                        <Button hidden={activeStep == 0} onClick={handleBack} sx={{ float: 'left', mt: 1, mr: 1 }}>Back</Button>
                                        <Button color='success' type='submit' variant="contained" sx={{float: 'right', mt: 1, mr: 1 }}>Complete!</Button>
                                        
                                    </div>
                                    
                                </Box>
                            </StepContent>
                        </Step>
                    </Stepper>

                )}
                    



                    <div hidden={!isSending} className="custom-modal">
                        <div className="container modal-dialog modal-dialog-centered">
                            <div className="modal-content text-center">
                                <div className="modal-body py-5">

                                    

                                    {/* If Accepted */}
                                    
                                    
                                    <div>
                                        <img src="/img-loan-approved.svg" alt="" />
                                        <p className="text-primary font-weight-bold mt-4">Congratulations</p>
                                        <p className="h4">Your Loan application was submitted!</p>
                                        <p>The loan application is being proccessed. We will inform to you about the processs.</p>
                                        <button type="button" className="btn btn-primary rounded-lg shadow-lg w-75" onClick={() => {navigate('/')}}>
                                            View Loan
                                        </button>
                                    </div>

                                    {/* If Rejected */}
                                    {/* <div>
                <img src="/img-loan-rejected.svg" alt="" />

                <p className="text-primary font-weight-bold">Sorry</p>
                <p className="h4">Your Loan was Rejected</p>
                <p>We are sorry, your application does not meet our requirements, please try again.</p>
                <button type="button" className="btn btn-primary rounded-lg shadow-lg w-75" onClick={() => setLoanState(0)}>OK</button>

              </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                

            </Box>

            


        </Layout>
    );
}

const mapStateToProps = (state: any) => ({ session: state.session, application : state.application.currentApplication })

export default connect(mapStateToProps)(NewApplication);