export const generateApiEndpoint = (url: string) => {
    // !! Do not add trailing slash at the end of the string.
    return `${process.env.REACT_APP_APIURL}${url}`;
  };
  
export const localStorageKeys = {
    accessToken : 'accesstoken'
}
  
export const setAccessToken = (val : string)=>{
  localStorage.setItem(localStorageKeys.accessToken, val);
}


export const removeAccessToken = ()=>{
  localStorage.removeItem(localStorageKeys.accessToken);
}

export const getAccessToken = ()=>{
  return localStorage.getItem(localStorageKeys.accessToken);
}