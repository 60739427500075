import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import dashboardReducer from './containers/dashboard/dashboardSlice';
import sessionReducer from './containers/session/sessionSlice';
import applicationReducer from './containers/applications/applicationSlice';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';


const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
  });

export const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    session : sessionReducer,
    application : applicationReducer
  },
});

export const history = createReduxHistory(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
