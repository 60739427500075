import client from './client';
import { generateApiEndpoint } from './utils';
// A mock function to mimic making an async request for data
export async function getLogin(data : any) {

  const endpoint = generateApiEndpoint('api/applicant/login');
  const response = await client.post(endpoint,  data);

  return response.data;
}

export async function getCurrentUser() {

  const endpoint = generateApiEndpoint('api/applicant/me');
  const response = await client.get(endpoint);

  return response.data;
}

export async function getSignup(data : any) {
  
  const endpoint = generateApiEndpoint('api/applicant/signup');
  const response = await client.post(endpoint,  data);

  return response.data;
}


export async function sendMobileCode(data : any) {
  
  const endpoint = generateApiEndpoint('api/applicant/send-mobile-code');
  const response = await client.post(endpoint,  data);

  return response.data;
}

export async function verifyMobileCode(data : any) {
  
  const endpoint = generateApiEndpoint('api/applicant/verify-mobile-code');
  const response = await client.post(endpoint,  data);

  return response.data;
}


export async function sendEmailCode(data : any) {
  
  const endpoint = generateApiEndpoint('api/applicant/send-email-code');
  const response = await client.post(endpoint,  data);

  return response.data;
}

export async function verifyEmailCode(data : any) {
  
  const endpoint = generateApiEndpoint('api/applicant/verify-email-code');
  const response = await client.post(endpoint,  data);

  return response.data;
}

export async function addBankingVerification(data : any) {
  
  const endpoint = generateApiEndpoint('api/applicant/add-banking-verification');
  const response = await client.post(endpoint,  data);

  return response.data;
}
