import React, { FormEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
//import styles from './Login.module.css';
import { signupAsync, sessionSlice } from './sessionSlice';

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../components/layout/layout';
import { NavLink } from 'react-router-dom';
import { FactCheck } from '@mui/icons-material';
import { Box } from '@mui/system';
import { IoIosArrowBack } from 'react-icons/io';

import { SubmitHandler, useForm } from 'react-hook-form';
import { Alert, Snackbar, TextField,FormControl } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';

type RegisterValues = {
    name : string;
    username: string;
    password: string;
};

const Register = (session: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
        setValue,
        setError,
        clearErrors,
        trigger,
        getValues
    } = useForm<RegisterValues>({
        mode: 'all',
        reValidateMode: 'onChange',
    });

    const [showWarning, setShowWarning] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [isSending, setIsSending] = useState(false);

    const handlePassword = (event: any) => {

        const passValue = event.target.value;
        
        const hasNumberRegex = /\d/;
        const hasUpperCaseRegex = /[A-Z]/;
        const hasSymbolRegex = /[!@#$%^&*]/;
        const passLength = passValue.length >= 8 ? true : false;
        
        SetIsValidPassHasNumber(hasNumberRegex.test(passValue));
        SetIsValidPassHasSymbolUppcase(hasUpperCaseRegex.test(passValue) && hasSymbolRegex.test(passValue));
        SetIsValidPassLenght(passLength);

        setValue('password',event.target.value);
    };


    const [isBusy, setIsBusy] = useState(true);
    const [accountSuccess, setAccountSuccess] = useState(false)

    const [isValidPassLength,SetIsValidPassLenght] = useState(false);
    const [isValidPassHasSymbolUppcase,SetIsValidPassHasSymbolUppcase] = useState(false);
    const [isValidPassHasNumber,SetIsValidPassHasNumber] = useState(false);

  

    const onSubmit: SubmitHandler<RegisterValues> = async (data) => {
        
        if(getValues('username') == '')
        {
            console.log('username');
            return false;
        }
        else{
            trigger('username');
        }

        if(getValues('name') == '')
        {
            console.log('name');
            return false;
        }
        else{
            trigger('name');
        }
        
        if(isValidPassLength == true && isValidPassHasSymbolUppcase == true && isValidPassHasNumber == true)
        {   
            trigger('password');
        }
        else
        {
            return false;
        }

        setIsSending(true);
        const { name,  username, password } = data;
        const result = await dispatch(signupAsync({name: name,  email: username, password: password, password_confirmation : password }));
        
        setIsSending(false);
        if (result.payload != null && result.payload.message !== null) {
            setWarningMessage(result.payload.message);
            navigate('/');
        }
        else{
        
            setWarningMessage(result.payload.message);
            setShowWarning(true);
            setIsSending(false);
        }
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowWarning(false);
    };





    return (
        <Layout headerLogo={true} logoCenter={true} back={true} topbar={false} session={session} dark={false} nav={false} checkLogin={false} isBusy={session.session.isBusy}>

            <>
            <Snackbar open={showWarning} autoHideDuration={2000} style={{ margin: '0 auto' }} onClose={handleClose}>
                <Alert severity="warning" sx={{ width: '100%' }}>
                    {warningMessage}
                </Alert>
            </Snackbar>
            
            <Box sx={{ p: 0, maxWidth: { sm: `26.56rem` }, marginLeft: 'auto', marginRight: 'auto' }}>
                
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="w-25"><button onClick={() => navigate(-1)} className={`btn btn-link ${'text-dark'}`}><IoIosArrowBack /></button></div>
                        
                        <div className="w-100"><img src="/logo-moneymoves.svg" alt="logo money moves" className="img-fluid" /></div>
                        <div className="w-25"></div>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                    
                    <div className="py-5 text-center px-3">
                        <div className="text-center py-4"> <img src="/img-create-password.svg" alt="Create password on money moves" /></div>
                            <h1 className="h3">Register</h1>
                            <p>Enter your email address and full name to create an account.</p>

                            <FormControl fullWidth sx={{mb: 1}}>
                                <TextField
                                    {...register('username', { required: true })}
                                    error={errors.username ? true : false}
                                    label="Email address"
                                    style={{backgroundColor: '#fff'}} 
                                    defaultValue={getValues('username')}
                                    size="small"
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{mb: 1}}>
                                <TextField
                                    {...register('name', { required: true })}
                                    error={errors.name ? true : false}
                                    label="Full name"
                                    style={{backgroundColor: '#fff'}} 
                                    defaultValue={getValues('name')}
                                    size="small"
                                />
                            </FormControl>

                            <p>Choose a secure password that will be easy for you to remember.</p>

                            <FormControl fullWidth sx={{mb: 1}}>
                                <TextField
                                    type="text"
                                    {...register('password', { required: true })}
                                    error={errors.password ? true : false}
                                    label="Password"
                                    style={{backgroundColor: '#fff'}} 
                                    defaultValue={getValues('password')}
                                    onChange={handlePassword}
                                    size="small"
                                />
                            </FormControl>

                            <ul className="list-unstyled mt-3 text-left">
                                <li className="py-1"><span className={isValidPassLength == false ? 'text-muted' : 'text-success'}><FactCheck /></span> Has at least 8 characters</li>
                                <li className="py-1"><span className={isValidPassHasSymbolUppcase == false ? 'text-muted' : 'text-success'}><FactCheck /></span> Has an uppercase letter or symbol</li>
                                <li className="py-1"><span className={isValidPassHasNumber == false ? 'text-muted' : 'text-success'}><FactCheck /></span> Has a number</li>
                            </ul>
                            <LoadingButton
                                type="submit"
                                className="btn btn-primary py-3 btn-block" style={{ borderRadius: "10rem" }}
                                loading={isSending}
                                loadingPosition="center"
                                variant='contained'
                                loadingIndicator="Loading..."
                            >
                                Create Account
                            </LoadingButton>
                        </div>
                    
                    </form>
                </Box>

                {/* Create Password  */}
                <Box className="mt-auto p-4 text-center" sx={{ p: 0, maxWidth: { sm: `26.56rem` }, marginLeft: 'auto', marginRight: 'auto' }}>
                    
                    
                    
                
                            {accountSuccess ?
                                <div className="custom-modal">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content text-center">
                                            <div className="modal-body py-5">
                                                {/* If Accepted */}
                                                <div>
                                                    <img src="/icon-member-success.svg" alt="" />
                                                    <p className="text-primary font-weight-bold mt-4">Congratulations</p>
                                                    <p className="h4 font-weight-bold">You are member now!</p>
                                                    <p>Get ready to start using Moneymoves and get money to reach your goals.</p>
                                                    {/* <button type="button" className="btn btn-primary rounded-lg shadow-lg w-75">I'm ready to start</button> */}
                                                    <NavLink to="/">
                                                        <a className="btn btn-primary rounded-lg shadow-lg w-75">I'm ready to start</a>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            : undefined}

                </Box>
                
            </>


        </Layout>
    );
}

const mapStateToProps = (state: any) => ({ session: state.session })

export default connect(mapStateToProps)(Register);