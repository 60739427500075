import React, { useEffect, useState } from 'react';
import {FiChevronDown} from 'react-icons/fi';
import { connect } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';

//import styles from './Login.module.css';

import styles from './Dashboard.module.css';

import { Layout } from '../../components/layout/layout';
import { useNavigate } from 'react-router-dom';
import { loginAsync, sessionSlice } from '../session/sessionSlice';
import { Box } from '@mui/material';



const Employments = (session: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isBusy, setIsBusy] = useState(true);

  setTimeout(function(){setIsBusy(false)}, 1000);

  return (
    <Layout headerLogo={true} logoCenter={true} back={true} topbar={true} session={session} dark={true} nav={true} checkLogin={true} isBusy={session.session.isBusy}>
      <>
      <div className="rounded-top-lg mt-neg bg-white px-3 pt-4 pb-bottom-app">
        <h4 className="font-weight-bold mb-1">Employments</h4>
        <button type="button" className="btn btn-light px-0  bg-transparent">View <strong>All <FiChevronDown /></strong></button>
        
      </div>
      </>
    </Layout>
  );
}

const mapStateToProps = (state: any) => ({ session: state.session })

export default connect(mapStateToProps)(Employments);