import React, { ChangeEventHandler, FormEvent, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
//import styles from './Login.module.css';
import { addBankingVerificationAsync, loginAsync, sessionSlice } from '../session/sessionSlice';

import { Link, useLocation, useMatch } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../components/layout/layout';
import { Title } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { FiHelpCircle } from 'react-icons/fi'
import { AiOutlineLock } from 'react-icons/ai'
import { IoIosArrowBack } from 'react-icons/io'


import { NavLink } from 'react-router-dom';
import VerificationInput from 'react-verification-input';
import BackButton from '../../components/backbutton/back-button';
import helmet from 'helmet';





const VerificationBankingResult = (session: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [isBusy, setIsBusy] = useState(true);
    const [isPosted, setIsPosted] = useState(false);

    const addBankingVerification = async() => {
        const result = await dispatch(addBankingVerificationAsync({ 
            demo : queryParams.get('demo'), 
            instutition : queryParams.get('institution'),
            loginId : queryParams.get('loginId')  
        }));
        
        
        setIsPosted(true);
    }
    

    useEffect(() => {
        if(isPosted == false)
        {
            addBankingVerification();
        }
      }, [isPosted]);

    return (
        
        <Layout headerLogo={true} logoCenter={true} back={true} topbar={false} session={session} dark={true} nav={false} checkLogin={false} isBusy={session.session.isBusy}>
            <>
                <Typography variant="h4">
                Congratulations!
                </Typography>

                <Typography variant="subtitle1">
                    You verified banking account by using your FLINKS account.
                </Typography>
            </>
        </Layout>
    );
}

const mapStateToProps = (state: any) => ({ session: state.session})

export default connect(mapStateToProps)(VerificationBankingResult);